.socialFixed{
    width:30px;
    height: 250px;
    position: fixed;
    top:35%;
    right:0;
    display:none;
    z-index: 30;

    @media(min-width:576px){
        display:block;
    }

    .item{
        margin-top:5px;
        padding-top:0;
    }
}