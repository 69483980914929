.saleContact{
    h5{
        @include headFontBold;
        color:$ultraLightGrey;
        text-transform: uppercase;
        padding:15px 0 10px 0;

        @include media(small){
            padding:30px 0 20px 0;
        }
        
    }
    .item{
        padding-top:15px;
        font-size:14px;

        p{
            font-size:14px;
        }

        a{
            color:#212529;
        }    

        &+.item{
            margin-top:25px;
            @include media(small){
                margin-top:10px;
            }

            @include media(large){
                margin-top:0;
            }
        }

    }
}
