.opinions {
	background-color: $opinionBoxBackgroundColor;	 
    .titleSection{
        padding-bottom:0px;
		  color: $darkGoldOpinions;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }
	 .subtitleSection{
        		@include subtitleSection; 
	 }
}

.opinions-slider{

    &-item{

        padding:10px 60px;

        @include media(large){
            padding:10px 50px;
        }

        &__logo{
            text-align: center;
            height:60px;
            display:flex;
            align-items:center;
            padding-bottom:15px;
            img{
                margin:0 auto;
            }
        }
    
        &__text{
            text-align: justify;
            padding:12px 0;
            max-width: 930px;
            margin:0 auto;
        }
    
        &__author{
            text-align: center;
            padding-top:10px;   
            font-weight: 600;
            & > *{
                font-weight: 600;
            }
        }

    }

    .slick-arrow{
        width:30px;
        height:30px;
        background-size:cover;
        z-index: 22;
        font-size:0 !important;
        opacity:.9;
        transition:.2s;

        @include media(large){
            width:60px;
            height:60px;
        }

        &:hover{
            opacity:1;
        }

        &:before{
            content:'';
        }
    }

    .slick-prev{
        background-image:url('../images/opinions_prev.jpg');
        left:5px;
    }

    .slick-next{
        background-image:url('../images/opinions_next.png');
        right:5px;
    }

}