.location{
	background-color: $locationBackgroundColor;
	background: url('../images/bgLocation.png');
	background-repeat: no-repeat;
	background-position: right center; 
	padding-top:50px;
	overflow:hidden;
	padding-bottom:50px;
	 
    .titleSection{
        padding-bottom:0px;
		  color: $turquoiseLight;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }
	 
	.subtitleSection{
		@include subtitleSection;  
	}
	 
	.LokalizacjaText {		
        @media(max-width:992px){
				text-align: center;
        } 
	}
	.LokalizacjaText p{padding: 20px}
	.mainContainerLocation {padding: 0}
}