.offer{

    overflow:hidden;
    .item{
        margin-bottom:35px;

        .photoWrap{
            background-size:cover;
            background-position:center;
            background-repeat: no-repeat;
            min-height: 220px;

            @media(min-width:450px){
                min-height: 250px;
            }

            @media(min-width:576px){
                min-height: 350px;
            }
        }
        .textWrap{
            .textBox{
                max-width:700px;
                margin:0 auto;
                padding:40px 15px 50px 15px;
                .titleSection{
                    text-shadow: 2px 2px 7px #fff;
                    text-align: left;
                    padding-top:0;
                    padding-bottom:15px;

                    @media(min-width:1200px){
                        line-height: 60px;
                    }
                }

                p{
                    font-size:14px;
                    text-align: justify;
                }
                .btnBox{
                    padding-top:30px;
                }
            }
            
        }

        //item odd (nieparzysty)
        &:nth-of-type(odd){
            .textWrap{
                .textBox{
                    .titleSection{
                            @media(min-width:768px){
                                margin-left:-60px;
                                position: relative;
                                z-index: 2;
                            }
                            @media(min-width:1500px){
                                margin-left:-100px;
                            }
                            @media(min-width:1600px){
                                margin-left:-110px;
                            }
                            @media(min-width:1600px){
                                margin-left:-120px;
                            }
                            @media(min-width:1700px){
                                margin-left:-140px;
                            }
                            @media(min-width:1800px){
                                margin-left:-160px;
                            }
                        }
                    }
                }
            }
    
    
        
        //item even (parzysty)
        &:nth-of-type(even){

            .textWrap{
            
                .textBox{
                    .titleSection{
                            @media(min-width:768px){
                                margin-right:-60px;
                                position: relative;
                                z-index: 2;
                            }
                            @media(min-width:1500px){
                                margin-right:-100px;
                            }
                            @media(min-width:1600px){
                                margin-right:-110px;
                            }
                            @media(min-width:1600px){
                                margin-right:-120px;
                            }
                            @media(min-width:1700px){
                                margin-right:-140px;
                            }
                            @media(min-width:1800px){
                                margin-right:-160px;
                            }
                        }
                    }
                }
            }
        // end item even (parzysty)
    }
}




