.modal{
    color:$ultraLightGrey;
    
    .modal-body{
        font-size:13px;

        @media(min-width:576px){
            font-size:15px;
        }

        h5{
            font-weight:bold;
            font-size:16px;
            @media(min-width:576px){
                font-size:18px; 
            }
        }
    }
}