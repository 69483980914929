.yellowBigBtn,
.blueBigBtn{
    border-radius:0;
    padding: 8px;
    font-size:12px;
    width:100%;
    max-width: 140px;
    margin-bottom:2px;
    margin-top:2px;
    text-transform: uppercase;
    display: none;

    @media(min-width:350px){
        font-size: 13px;
    }

    @media(min-width:576px){
        //padding: 9px 30px;
        font-size:14px;
        max-width: 150px;
    }

    @media(min-width:768px){
        display:inline-block;
    }

    @media(min-width:992px){
        padding: 10px 9px;
        font-size:16px;
        max-width: 240px;
    }

    &:hover{
        opacity:.9;
    }

}

.yellowBigBtn{
    background-color:$orange;
    color:#fff !important;
    
    &:focus,
    &:active{
        background-color:$orange !important;
        color:#fff !important;
    }

    &.specialReserveBtn{
			font-weight:600;
            padding: 7px 10px;
			margin-left:auto;
			margin-right:auto;
			margin-top: 10px;
			margin-bottom: 10px;
            display:block !important;
            letter-spacing: .3px;

			@media(min-width:768px){
				padding: 10px 7px;
				max-width:205px;
			}
		
    }

}

.blueBigBtn{
    background-color:$blue;
    color:#fff;
    &:hover{
        color:#fff;
    }
    &:focus,
    &:active{
        background-color:$blue !important;
    }
}


.yellowSmallBtn{
    background-color:$orange;
    text-transform: uppercase;
    width: 100%;
    max-width: 140px;
    font-size:14px;
    color:#fff !important;
    border:0;

    &:hover{
        border:0;
        cursor:pointer;
        color:#fff !important;
    }

    @media(min-width:576px){
        //padding: 9px 30px;
        font-size:14px;
        max-width: 160px;
    }

    @media(min-width:768px){
        padding: 9px 30px;
        font-size:16px;
        max-width: 200px;
    }

    @media(min-width:992px){
        padding: 9px 30px;
        font-size:16px;
    }
}

.blueSmallBtn{
    background-color:$ultraLightGrey;
    text-transform: uppercase;
    width: 100%;
    max-width: 140px;
    font-size:14px;
    color:#fff !important;
    border:0;
    border-radius: 0;
    border-color:$ultraLightGrey !important;

    &:hover{
        color:#fff !important;
        border:none !important;
        cursor:pointer;
    }

    @media(min-width:576px){
        //padding: 9px 30px;
        font-size:14px;
        max-width: 150px;
    }

    @media(min-width:768px){
        padding: 7px 5px;
        font-size:14px;
        
    }

    @media(min-width:992px){
        padding: 8px 5px;
        font-size:14px;
    }
}

.sliderBtn{
    font-size:11px;
    background-color:transparent;
    border:1px solid #fff;
    margin:20px auto 0 auto;
    color:#fff;
    font-weight: bold;
    padding:4px 7px;
    position: relative;
    z-index: 10;
    transition:.3s;

    &:active,
    &:hover{
        background-color:$blue;
        color:#fff;
        text-decoration: none;
    }

    @media(min-width:768px){
        font-size:13px;
        padding:5px 10px;
    }
}

.mapBtn{
    background-color: #fff;
    color:#1d3649;
    font-size:14px;
    font-weight: 600;
    border-radius: 0;
    padding: 6px 31px;
    text-transform: uppercase;
}


.hvr-ripple-out.yellow:before{
    border: $orange solid 6px;
}

.hvr-ripple-out.blue:before{
    border: $ultraLightGrey solid 6px;
}

.hvr-ripple-out.white:before{
    border: #fff solid 6px;
}


.orangeBtn{
    background-color: $orange !important;
    color:#fff !important;

    @include on-event{
        background-color: $orange !important;
    }
}

.hvr-ripple-out.orange:before{
    border: $orange solid 6px;
}
