.banners{
    padding-top:35px;
    padding-bottom:15px;
    .item{
        height:250px;
        position:relative;
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;

        &:hover{
            .textWrap{
                opacity:1;
            }

            .title{
                opacity:0;
            }
        }
        
        @media(min-width:365px){
            height:350px;
        }

        .title{
            display: flex;
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            transition:.4s;
            opacity:1;
            width:100%;
            align-items: center;
            justify-content: center;

            h5{
                background-color:$orange;
                color:#fff;
                padding:15px 15px;
                font-size: 18px;
                @media(min-width:576px){
                    padding:15px 25px;
                    
                }

                @media(min-width:768px){
                    font-size: 20px;
                }
                
            }
        }

        .textWrap{
            width:100%;
            height:100%;
            position:absolute;
            background-color:rgba(0,0,0,.6);
            top:0;
            left:0;
            opacity:0;
            transition:.4s;
            
            .textBox{
                position:absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                width:100%;
                padding:25px;
                text-align: center;
                p{
                    color:#fff;
                }

                .btnBox{
                    margin-top:20px;
                    a{
                        font-size:15px;
                    }
                }
            }
        }

    }
}