.mobileBottomPanel{
    width:100%;
    position:fixed;
    bottom:0;
    left:0;
    z-index: 3000;
    background-color: #988e6f;
    display:none;
    padding:0;

    .item{
        background-color: #988e6f;
        padding:7px;
        text-align: center;
        width:100%;


        &:hover{
            cursor:pointer;
            opacity:.7;
        }
		  a:hover {
				text-decoration: none;
		  }

        img{
            max-width: 18px;
        }
        p{
            color:#fff;
            font-size:10px;
            margin-top:3px;
            text-transform: uppercase;
        }

        &.book{
            background-color:#fff;
            
            p{
                color:#988e6f;
            }
        }

    }
}