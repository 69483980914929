footer{
    margin-top:10px;
    background-color:$footerBackgroundColor;

    /* footerTop*/
    .footerTop{ 
        background-color:$footerBackgroundColor;
        color:$darkGold;
        padding:25px 0;
		  img {
		  	width: 100%;	
	        @media(max-width:768px){
		  			width: 50%;	
	        } 
		  }	
		  .mainContainer {
            @media(max-width:576px){
                text-align: center!important;
            } 
				.logofooter {
					text-align: left;
	            @media(max-width:576px){
	                text-align: center!important;
	            } 
				}
			}	
		  a{
		  	color:$darkGold;
			text-dcoration: none;
			}
        p{
            font-size:13px;
            @media(min-width:576px){
                font-size:14px;
            } 
        }
         .dane p{
            font-size:18px;
				font-family: monarcha, serif;
				font-weight: 700;
				font-style: normal;
            @media(min-width:576px){
                font-size:14px;
            } 
			}
			.txt {
				color:$textColor;
				text-transform: uppercase;
				text-align: right;
				 @media(max-width:576px){
				     text-align: center!important;
				 } 
			}
			.icon {
				img {
					vertical-align: middle;
					width: 50px;
					@media(max-width:768px){
						width: 30px;
					} 
					text-align: right;
					@media(max-width:576px){
						text-align: center!important;
					} 
				}
				text-align: center;
				@media(min-width:576px){
					text-align: right;
				}
			}
			
			
        .navFooter{
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;

            .nav-item{
                display:flex;

                a,span{
                    color:$textColor;
                    font-size:16px;
                    padding:1px 6px;
        
                    &:before{
                        height:1px;
                    }

                    &:hover{
                        cursor:pointer;
                        text-decoration: none;
                    }
        
                    color:$textColor;
        
                    @media(min-width:576px){
                        font-size:14px;
                    }  

                }

                &.active{
                    a{
                        font-weight: bold;
                    }
                }
            }
        }

    }
    /* ./footerTop*/


   


    .column{
        border-left:2px solid #fff;
        padding-top:10px;
        padding-bottom:10px;
        padding-left:15px;

        @include media(small){
            padding-left:30px;
        }
    }



     /*footer bottom*/
     .copyBox{

        text-align: center;
        padding-top:15px;
        padding-bottom:15px;
        color:$textColor;

        p{
            font-size:12px;
            @media(min-width:576px){
                font-size:14px;
            }
        }
        
    }
    /* ./footer bottom*/


}