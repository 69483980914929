.boxInfo{
    background-color:$yellow;
    display:inline-block;
    margin: 3px 0 8px;
    padding: 15px;
    display: flex;
    align-items:center;
    p{
        font-size:24px;
        color:$blue;
        font-weight: bold;
    }
}