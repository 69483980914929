body{
    // opacity:0;
    transition: .1s;
}

body.fadeIn{
    opacity:1;
}

.mainContainer{
    /* max-width: 1400px; */
    margin:0 auto;

    @media (min-width:992px) and (max-width:1199px){
        padding-left:25px;
        padding-right:25px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:50px;
        padding-right:50px;
    }
}

.bold{
    @include headFontBold;
}

a{
    color:#000;
}

/* BACKGROUND */

.darkBlueBg{
    background-color: $ultraLightGrey;
}

.blueBg{
    background-color: $blue;
}

.lightBlueBg{
    background-color: $darkGold;
}

.heavenlyBg{
    background-color: $heavenly;
}

.orangeBg{
    background-color: $orange;
}

.lightGrayBg{
    background-color: $lightGray;
}

.whiteBg{
    background-color: #fff;
}
.roomBg{
    background-color: #fafafa
}


/* COLORS */

.orangeColor{
    color:$orange;
}

.darkBlueColor{
    color:$ultraLightGrey;
}

.whiteColor{
    color:#fff;
}


p{
    font-size:14px;
}

@media(min-width:768px){
    p{
        font-size:15px;
    }
}

@media(min-width:1200px){
    p{
        font-size:16px;
    }
}

.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.subtitleSection{
    color:$textColor;
    font-size:28px;
    text-align:center;
    padding-bottom:15px;
	 text-transform: lowercase;
    @include subTitleFontLight;

    @include media(xsmall) {
        font-size:18px;
    }

    @include media(small) {
        font-size:18px;
    }

    @include media(medium) {
        font-size:20px;
    }

    @include media(large) {
        font-size:24px;
    }

    @include media(xxlarge) {
        font-size:28px;
    }

    strong,
    &--bold{
        @include headFontBold;
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:16px;

        @include media(xsmall) {
            font-size:18px;
        }

        @include media(medium) {
            font-size:18px;
        }
 
    }

}
.titleSection{
    color:$ultraLightGrey;
    font-size:52px;
    text-align:center;
    padding-bottom:25px;
	 text-transform: capitalize ;
    @include headFontBold;

    @include media(xsmall) {
        font-size:33px;
    }

    @include media(small) {
        font-size:36px;
    }

    @include media(medium) {
        font-size:38px;
    }

    @include media(large) {
        font-size:40px;
    }

    @include media(xxlarge) {
        font-size:52px;
    }

    strong,
    &--bold{
        @include headFontBold;
    }

    &--small{
        font-size:26px;

        @include media(xsmall) {
            font-size:28px;
        }
    
        @include media(small) {
            font-size:30px;
        }
    
        @include media(medium) {
            font-size:32px;
        }
    
        @include media(large) {
            font-size:34px;
        }
    
        @include media(xxlarge) {
            font-size:36px;
        }
    }

    &--xsmall{
        font-size:20px;

        @include media(xsmall) {
            font-size:22px;
        }

        @include media(medium) {
            font-size:24px;
        }
 
    }

}



.owl-item.active.center {
    transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled{
    display:none !important;
}
.container-fluid {width: 90%!important;}