.specialForm{

    .titleSection{
        padding-bottom:0;
		  color: #0a7484;	
        @include headFontBold;
	 		font-weight: 500;  
    }

    h5{
        padding-bottom:25px;
        text-transform: uppercase;
        color:$ultraLightGrey;
        font-size:16px;
        text-transform: lowercase;
    }

    .form-group {
        margin-bottom: 25px;
    }

    input,textarea{
			box-shadow: 0 !important;
			padding: 10px 10px;
			transition:.3s;
			color:#998e70!important;
			display: block;
			width: 100%;
			border: 0;
			border-bottom: 2px solid #998e70;
			border-radius: 3px;
			font-size: 1rem;
			font-weight: 300;
			font-family:  Lato,sans-serif;
			text-transform: uppercase;
			background-color:#e2e1df;
			transition: color 0.3s, box-shadow 0.3s;
        &:focus{
            box-shadow: none !important;
            border-color:$darkGold;
        }
    }
	 .form-control:focus{
			background-color:#EDECEB;
	 }
	.wpcf7-submit{
		display: block;
		width: 100%;
		font-size: 1.2rem;
		font-weight: 300;
		font-family:  Lato,sans-serif;
		text-transform: uppercase;
		background-color:#998e70;
		color: #fff!important;
		&:hover{
			background-color: #a69b7c;
		}
	}
	.form-check-label {
        color:#998e70!important;
	}
    .checkWrap{
        .form-group{
            margin-bottom: 7px;
        }
    }

    a{
        color:#000;
        text-transform: uppercase;
        text-decoration: underline;
    }

    .agree{
        
        display: flex;

        font-size:12px;

        @include media(medium){
            font-size:13px;
        }

        @include media(xlarge){
            font-size:14px;
        }

        p{
            font-size:12px;

            @include media(medium){
                font-size:13px;
            }

            @include media(xlarge){
                font-size:14px;
            }
        }
    }

    .showFormPanel{
        padding-top: 8px;
        padding-bottom: 15px;

        .blueLine{
            width:100%;
            height:2px;
            background-color:#8e9ba4;
        }
        img{
            margin-top: -32px;
            position: relative;
            z-index: 3;

            &:hover{
                cursor:pointer;
            }
        }
        .showFormText{
            color:$blue;
            font-size:13px;
            margin-top:-6px;
        }
    }

    
    .formWrap{
        display:none;
    }

    .otherInfo{
        p{
            color:$blue;
            font-size:13px;
        }
    }

    span.wpcf7-not-valid-tip{
        font-size:13px !important;
        margin-left: 15px;
        padding-right: 15px;
    }

    div.wpcf7-validation-errors, div.wpcf7-acceptance-missing,{
        border:0 !important;
        text-align: center;
        color:#ff0000;
    }




    .form-check-input{
        margin-top: -11px;

        &:hover{
            cursor: pointer;
        }
    }

    .div.wpcf7-mail-sent-ok{
        color: #398f14;
        border:none;
        text-align: center;
    }

    div.wpcf7-response-output{
        margin-top:0 !important;
    }

}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing,{
    border:0 !important;
    text-align: center;
    color:#ff0000;
}


