.pageContact{
			.icon img{
				width: 25px;
				height: auto;
			}
    .mainContent{

	    .titleSection{
	        text-transform: uppercase;
	    }
	    .item{
	        .textWrap{
	            .textBox{
	                a{
	                    // color: #003852;
	                    // font-size:14px;
	                    color: #000;
	                }
	            }
	        }
	        
	        .icon{
	            width:50px;

	            img{
	                width:50px;
	            }
	        }

	        .text{
	            p{
	                padding-top:2px !important; 
	                text-align: left !important;
	                font-size:15px !important;
	            }
	            p+p{
	                padding-top:2px;
	            }
	        }
	    }

    }
	.formContact{
		background-color: #e2e1df;
	}
	.obiekty{}
	.locationKontakt{
	    .titleSection{
	        padding-bottom:0px;
			  color: #41b9ba;	
	        @include headFontBold;  
	        @media(min-width:576px){
	            padding-bottom:0px;
	        } 
	    }
		.subtitleSection{
			@include subtitleSection;  
			  color: #676767;	
		}
		.nazwa{
			font-size:16px;
			font-weight: 600;
			margin-bottom: -10px;
		}
		.opis {
			p{
			font-size:13px;
			}			
			.icon{
			    width:50px;

			    img{
			        width:50px;
			    }
			}
		}
		.odleglosci {    
			padding: 10px 0;
			color: #42babb;
		}
	}
} 