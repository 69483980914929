html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  transition: .1s;
}

body.fadeIn {
  opacity: 1;
}

.mainContainer {
  /* max-width: 1400px; */
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.bold {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .bold {
    font-size: 52px;
  }
}

a {
  color: #000;
}

/* BACKGROUND */
.darkBlueBg {
  background-color: #eaeaea;
}

.blueBg {
  background-color: #28487e;
}

.lightBlueBg {
  background-color: #988e6f;
}

.heavenlyBg {
  background-color: #9BCBEB;
}

.orangeBg {
  background-color: #E35205;
}

.lightGrayBg {
  background-color: #e2e1e0;
}

.whiteBg {
  background-color: #fff;
}

.roomBg {
  background-color: #fafafa;
}

/* COLORS */
.orangeColor {
  color: #E35205;
}

.darkBlueColor {
  color: #eaeaea;
}

.whiteColor {
  color: #fff;
}

p {
  font-size: 14px;
}

@media (min-width: 768px) {
  p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subtitleSection {
  color: #575758;
  font-size: 28px;
  text-align: center;
  padding-bottom: 15px;
  text-transform: lowercase;
  font-family: 'amazoneplregular';
}

@media (min-width: 460px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 1440px) {
  .subtitleSection {
    font-size: 28px;
  }
}

.subtitleSection strong, .subtitleSection--bold {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 52px;
  }
}

.subtitleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .subtitleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .subtitleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .subtitleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1440px) {
  .subtitleSection--small {
    font-size: 36px;
  }
}

.subtitleSection--xsmall {
  font-size: 16px;
}

@media (min-width: 460px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

.titleSection {
  color: #eaeaea;
  font-size: 52px;
  text-align: center;
  padding-bottom: 25px;
  text-transform: capitalize;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 460px) {
  .titleSection {
    font-size: 33px;
  }
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (min-width: 1440px) {
  .titleSection {
    font-size: 52px;
  }
}

.titleSection strong, .titleSection--bold {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .titleSection strong, .titleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection strong, .titleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection strong, .titleSection--bold {
    font-size: 52px;
  }
}

.titleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .titleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .titleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .titleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .titleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1440px) {
  .titleSection--small {
    font-size: 36px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (min-width: 460px) {
  .titleSection--xsmall {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .titleSection--xsmall {
    font-size: 24px;
  }
}

.owl-item.active.center {
  transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

.container-fluid {
  width: 90% !important;
}

@font-face {
  font-family: 'amazoneplregular';
  src: url("../fonts/amazonepl_1040pl-webfont.eot");
  src: url("../fonts/amazonepl_1040pl-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/amazonepl_1040pl-webfont.woff2") format("woff2"), url("../fonts/amazonepl_1040pl-webfont.woff") format("woff"), url("../fonts/amazonepl_1040pl-webfont.ttf") format("truetype"), url("../fonts/amazonepl_1040pl-webfont.svg#amazoneplregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Lato', sans-serif;
}

.yellowBigBtn,
.blueBigBtn {
  border-radius: 0;
  padding: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 140px;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: uppercase;
  display: none;
}

@media (min-width: 350px) {
  .yellowBigBtn,
  .blueBigBtn {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .yellowBigBtn,
  .blueBigBtn {
    font-size: 14px;
    max-width: 150px;
  }
}

@media (min-width: 768px) {
  .yellowBigBtn,
  .blueBigBtn {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .yellowBigBtn,
  .blueBigBtn {
    padding: 10px 9px;
    font-size: 16px;
    max-width: 240px;
  }
}

.yellowBigBtn:hover,
.blueBigBtn:hover {
  opacity: .9;
}

.yellowBigBtn {
  background-color: #E35205;
  color: #fff !important;
}

.yellowBigBtn:focus, .yellowBigBtn:active {
  background-color: #E35205 !important;
  color: #fff !important;
}

.yellowBigBtn.specialReserveBtn {
  font-weight: 600;
  padding: 7px 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block !important;
  letter-spacing: .3px;
}

@media (min-width: 768px) {
  .yellowBigBtn.specialReserveBtn {
    padding: 10px 7px;
    max-width: 205px;
  }
}

.blueBigBtn {
  background-color: #28487e;
  color: #fff;
}

.blueBigBtn:hover {
  color: #fff;
}

.blueBigBtn:focus, .blueBigBtn:active {
  background-color: #28487e !important;
}

.yellowSmallBtn {
  background-color: #E35205;
  text-transform: uppercase;
  width: 100%;
  max-width: 140px;
  font-size: 14px;
  color: #fff !important;
  border: 0;
}

.yellowSmallBtn:hover {
  border: 0;
  cursor: pointer;
  color: #fff !important;
}

@media (min-width: 576px) {
  .yellowSmallBtn {
    font-size: 14px;
    max-width: 160px;
  }
}

@media (min-width: 768px) {
  .yellowSmallBtn {
    padding: 9px 30px;
    font-size: 16px;
    max-width: 200px;
  }
}

@media (min-width: 992px) {
  .yellowSmallBtn {
    padding: 9px 30px;
    font-size: 16px;
  }
}

.blueSmallBtn {
  background-color: #eaeaea;
  text-transform: uppercase;
  width: 100%;
  max-width: 140px;
  font-size: 14px;
  color: #fff !important;
  border: 0;
  border-radius: 0;
  border-color: #eaeaea !important;
}

.blueSmallBtn:hover {
  color: #fff !important;
  border: none !important;
  cursor: pointer;
}

@media (min-width: 576px) {
  .blueSmallBtn {
    font-size: 14px;
    max-width: 150px;
  }
}

@media (min-width: 768px) {
  .blueSmallBtn {
    padding: 7px 5px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .blueSmallBtn {
    padding: 8px 5px;
    font-size: 14px;
  }
}

.sliderBtn {
  font-size: 11px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: 20px auto 0 auto;
  color: #fff;
  font-weight: bold;
  padding: 4px 7px;
  position: relative;
  z-index: 10;
  transition: .3s;
}

.sliderBtn:active, .sliderBtn:hover {
  background-color: #28487e;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .sliderBtn {
    font-size: 13px;
    padding: 5px 10px;
  }
}

.mapBtn {
  background-color: #fff;
  color: #1d3649;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  padding: 6px 31px;
  text-transform: uppercase;
}

.hvr-ripple-out.yellow:before {
  border: #E35205 solid 6px;
}

.hvr-ripple-out.blue:before {
  border: #eaeaea solid 6px;
}

.hvr-ripple-out.white:before {
  border: #fff solid 6px;
}

.orangeBtn {
  background-color: #E35205 !important;
  color: #fff !important;
}

.orangeBtn:hover, .orangeBtn:active, .orangeBtn:focus {
  background-color: #E35205 !important;
}

.hvr-ripple-out.orange:before {
  border: #E35205 solid 6px;
}

.header {
  width: 100% !important;
  background-color: rgba(152, 142, 111, 0.8);
  transition: .3s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}

@media (min-width: 992px) {
  .header {
    background-color: transparent;
  }
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(152, 142, 111, 0.8);
}

.header--fixed .navbar-brand__logo {
  width: 80px;
}

@media (min-width: 576px) {
  .header--fixed .navbar-brand__logo {
    width: 85px;
  }
}

@media (min-width: 768px) {
  .header--fixed .navbar-brand__logo {
    width: 90px;
  }
}

@media (min-width: 992px) {
  .header--fixed .navbar-brand__logo {
    width: 95px;
  }
}

@media (min-width: 1200px) {
  .header--fixed .navbar-brand__logo {
    width: 100px;
  }
}

@media (min-width: 1440px) {
  .header--fixed .navbar-brand__logo {
    width: 110px;
  }
}

.header--fixed .navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.header--fixed .dropdown-menu {
  margin-top: 0 !important;
}

@media (min-width: 992px) {
  .header--fixed .dropdown-menu {
    background-color: rgba(152, 142, 111, 0.8) !important;
  }
}

.mainSlider {
  height: calc(100vh);
  min-height: 650px;
  padding-top: 0 !important;
}

.mainSlider .item {
  height: calc(100vh);
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: none;
}

@media (min-width: 576px) {
  .mainSlider .item {
    min-height: 500px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item {
    min-height: 600px;
  }
}

@media (min-width: 1600px) {
  .mainSlider .item {
    min-height: 700px;
  }
}

.mainSlider .item .overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mainSlider .item .textBox {
  position: absolute;
  bottom: 20%;
  width: 100%;
  padding-left: 45px;
  padding-right: 45px;
}

.mainSlider .item .textBox .mainText {
  color: #fff;
  font-family: monarcha;
  font-weight: 500;
  font-style: normal;
  font-size: 26px;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  text-transform: inherit;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .mainText {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .mainText {
    font-size: 45px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .mainText {
    font-size: 50px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .mainText {
    font-size: 50px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item .textBox .mainText {
    font-size: 60px;
  }
}

.mainSlider .item .textBox .secondText {
  color: #fff;
  font-family: 'amazoneplregular';
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  padding-top: 5px;
}

.mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
  font-size: 23px;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
    font-size: 40px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item .textBox .secondText, .mainSlider .item .textBox .secondText > * {
    font-size: 48px;
  }
}

.mainSlider .item .textBox .btnBox {
  padding-top: 5px;
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .btnBox {
    padding-top: 20px;
  }
}

.mainSlider .item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .mainSlider .item.onMobile {
    display: none;
  }
}

.mainSlider .item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .mainSlider .item.onDesktop {
    display: block;
  }
}

.mainSlider .item.onMobile.onDesktop {
  display: block;
}

.mainSlider .owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.mainSlider .owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%);
}

.mainSlider .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
  background-color: #fff;
}

.mainSlider .owl-theme .owl-dots .owl-dot.active span, .mainSlider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #D6D6D6;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev,
.mainSlider .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    margin: 20px;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    width: 60px;
    height: 60px;
  }
}

.mainSlider .owl-carousel .owl-nav button.owl-prev:hover,
.mainSlider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left.png");
}

.mainSlider .owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right.png");
}

section {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
}

.specialOffers {
  background: url("../images/specjaloffersBackground.png");
  background-repeat: no-repeat;
  background-position: 0 20px;
  background-color: #e2e1e0;
  padding-top: 40px;
  overflow: hidden;
  padding-bottom: 50px;
}

.specialOffers .titleSection {
  padding-bottom: 0px;
  color: #0b7284;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .specialOffers .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .specialOffers .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .specialOffers .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .specialOffers .titleSection {
    padding-bottom: 0px;
  }
}

.specialOffers .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .specialOffers .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .specialOffers .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .specialOffers .subtitleSection {
    font-size: 28px;
  }
}

.specialOffers .titleOffer {
  padding-bottom: 10px;
  font-size: 21px;
  font-family: Lato,sans-serif;
  color: #0b7284;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .specialOffers .titleOffer {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .specialOffers .titleOffer {
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.specialOffers .titleOffer strong {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .specialOffers .titleOffer strong {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .specialOffers .titleOffer strong {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .specialOffers .titleOffer strong {
    font-size: 52px;
  }
}

.specialOffers h6.krotkaCena {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 300;
  letter-spacing: 3px;
}

@media (max-width: 992px) {
  .specialOffers h6.krotkaCena {
    margin-bottom: 0;
  }
}

.specialOffers p.krotkiTekst {
  padding-bottom: 15px;
  color: #666666;
}

.specialOffers .STRZmore {
  padding-top: 20px;
}

.specialOffers .STRZmore img {
  width: 42px;
  height: 16px;
  margin-left: 20px;
}

.specialOffers .STRZmore a {
  text-transform: uppercase;
  color: #666666;
}

.specialOffers .item {
  background-color: #fff;
}

.specialOffers .item .textWrap {
  padding-right: 50px;
  padding-left: 50px;
}

@media (max-width: 992px) {
  .specialOffers .item .textWrap {
    padding-right: 0;
    padding-left: 0;
  }
}

.specialOffers .item .textWrap .elementWrap {
  padding: 0 70px;
}

.specialOffers .item:nth-of-type(odd) .photoWrap {
  padding-left: 0;
}

@media (min-width: 992px) {
  .specialOffers .item:nth-of-type(odd) .photoWrap {
    order: 2;
  }
}

@media (max-width: 768px) {
  .specialOffers .item:nth-of-type(odd) .photoWrap {
    padding-left: 15px;
  }
}

.specialOffers .item:nth-of-type(odd) .textWrap {
  margin: 20px 0;
}

@media (min-width: 992px) {
  .specialOffers .item:nth-of-type(odd) .textWrap {
    order: 1;
    margin: 0;
  }
}

.specialOffers .item:nth-of-type(even) .photoWrap {
  padding-right: 0;
}

@media (min-width: 992px) {
  .specialOffers .item:nth-of-type(even) .photoWrap {
    order: 1;
  }
}

@media (max-width: 768px) {
  .specialOffers .item:nth-of-type(even) .photoWrap {
    padding-right: 15px;
  }
}

.specialOffers .item:nth-of-type(even) .textWrap {
  margin: 20px 0;
}

@media (min-width: 992px) {
  .specialOffers .item:nth-of-type(even) .textWrap {
    order: 2;
    margin: 0;
  }
}

.location {
  background-color: #eeeeee;
  background: url("../images/bgLocation.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-top: 50px;
  overflow: hidden;
  padding-bottom: 50px;
}

.location .titleSection {
  padding-bottom: 0px;
  color: #41b8bb;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .location .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .location .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .location .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .location .titleSection {
    padding-bottom: 0px;
  }
}

.location .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .location .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .location .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .location .subtitleSection {
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .location .LokalizacjaText {
    text-align: center;
  }
}

.location .LokalizacjaText p {
  padding: 20px;
}

.location .mainContainerLocation {
  padding: 0;
}

#map {
  height: 640px;
  width: 100%;
}

footer {
  margin-top: 10px;
  background-color: #fafafa;
  /* footerTop*/
  /* ./footerTop*/
  /*footer bottom*/
  /* ./footer bottom*/
}

footer .footerTop {
  background-color: #fafafa;
  color: #988e6f;
  padding: 25px 0;
}

footer .footerTop img {
  width: 100%;
}

@media (max-width: 768px) {
  footer .footerTop img {
    width: 50%;
  }
}

@media (max-width: 576px) {
  footer .footerTop .mainContainer {
    text-align: center !important;
  }
}

footer .footerTop .mainContainer .logofooter {
  text-align: left;
}

@media (max-width: 576px) {
  footer .footerTop .mainContainer .logofooter {
    text-align: center !important;
  }
}

footer .footerTop a {
  color: #988e6f;
  text-dcoration: none;
}

footer .footerTop p {
  font-size: 13px;
}

@media (min-width: 576px) {
  footer .footerTop p {
    font-size: 14px;
  }
}

footer .footerTop .dane p {
  font-size: 18px;
  font-family: monarcha, serif;
  font-weight: 700;
  font-style: normal;
}

@media (min-width: 576px) {
  footer .footerTop .dane p {
    font-size: 14px;
  }
}

footer .footerTop .txt {
  color: #575758;
  text-transform: uppercase;
  text-align: right;
}

@media (max-width: 576px) {
  footer .footerTop .txt {
    text-align: center !important;
  }
}

footer .footerTop .icon {
  text-align: center;
}

footer .footerTop .icon img {
  vertical-align: middle;
  width: 50px;
  text-align: right;
}

@media (max-width: 768px) {
  footer .footerTop .icon img {
    width: 30px;
  }
}

@media (max-width: 576px) {
  footer .footerTop .icon img {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  footer .footerTop .icon {
    text-align: right;
  }
}

footer .footerTop .navFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

footer .footerTop .navFooter .nav-item {
  display: flex;
}

footer .footerTop .navFooter .nav-item a, footer .footerTop .navFooter .nav-item span {
  color: #575758;
  font-size: 16px;
  padding: 1px 6px;
  color: #575758;
}

footer .footerTop .navFooter .nav-item a:before, footer .footerTop .navFooter .nav-item span:before {
  height: 1px;
}

footer .footerTop .navFooter .nav-item a:hover, footer .footerTop .navFooter .nav-item span:hover {
  cursor: pointer;
  text-decoration: none;
}

@media (min-width: 576px) {
  footer .footerTop .navFooter .nav-item a, footer .footerTop .navFooter .nav-item span {
    font-size: 14px;
  }
}

footer .footerTop .navFooter .nav-item.active a {
  font-weight: bold;
}

footer .column {
  border-left: 2px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  footer .column {
    padding-left: 30px;
  }
}

footer .copyBox {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #575758;
}

footer .copyBox p {
  font-size: 12px;
}

@media (min-width: 576px) {
  footer .copyBox p {
    font-size: 14px;
  }
}

.socialFixed {
  width: 30px;
  height: 250px;
  position: fixed;
  top: 35%;
  right: 0;
  display: none;
  z-index: 30;
}

@media (min-width: 576px) {
  .socialFixed {
    display: block;
  }
}

.socialFixed .item {
  margin-top: 5px;
  padding-top: 0;
}

.modal {
  color: #eaeaea;
}

.modal .modal-body {
  font-size: 13px;
}

@media (min-width: 576px) {
  .modal .modal-body {
    font-size: 15px;
  }
}

.modal .modal-body h5 {
  font-weight: bold;
  font-size: 16px;
}

@media (min-width: 576px) {
  .modal .modal-body h5 {
    font-size: 18px;
  }
}

.reservationPanel {
  background-color: #eaeaea;
  margin-bottom: 50px;
  z-index: 20;
  position: relative;
  display: none;
  padding: 0;
  margin-top: -104px;
  max-width: 800px;
}

@media (min-width: 576px) {
  .reservationPanel {
    display: block;
  }
}

.reservationPanel .form-inline .form-group {
  display: unset !important;
  margin: 0 7px 10px;
}

.reservationPanel .-form {
  justify-content: center;
}

.reservationPanel .-form label {
  display: block !important;
  font-size: 9px;
  clear: both;
  margin: 0;
  padding: 5px 0 0px 17px;
}

.reservationPanel .-form h5 {
  color: #fff;
  padding: 5px 30px;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  letter-spacing: .3px;
  margin-top: 4px;
  padding-left: 40px;
  font-size: 21px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 15px 30px;
}

@media (min-width: 576px) {
  .reservationPanel .-form h5 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .reservationPanel .-form h5 {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .reservationPanel .-form h5 {
    font-size: 52px;
  }
}

@media (min-width: 1200px) {
  .reservationPanel .-form h5 {
    width: 250px;
    text-align: center;
    padding: 5px 30px;
  }
}

.reservationPanel .-form input, .reservationPanel .-form select {
  margin: 0 5px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
  border-radius: 0;
  padding: 0 10px 0 10px;
  max-width: 175px;
  font-size: 12px;
  cursor: pointer;
  width: 170px;
  min-height: 35px;
  font-family: Lato,sans-serif;
}

@media (min-width: 767px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    font-size: 12px;
  }
}

@media (min-width: 870px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    font-size: 12px;
  }
}

@media (min-width: 1300px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    width: 175px;
  }
}

.reservationPanel .-form input:focus, .reservationPanel .-form select:focus {
  background-color: #fff;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
}

.reservationPanel .-form input.inputDateIn, .reservationPanel .-form select.inputDateIn {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 16px 16px;
}

.reservationPanel .-form input.inputDateOut, .reservationPanel .-form select.inputDateOut {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 16px 16px;
}

.reservationPanel .-form input.promocode, .reservationPanel .-form select.promocode {
  background-image: url("../images/promoCode_bg.png");
  background-repeat: no-repeat;
  background-position: 90% center;
}

.reservationPanel .-form select {
  font-family: Lato,sans-serif;
}

.reservationPanel .-form select option {
  padding: 5px;
}

.reservationPanel .-form button {
  font-weight: bold;
  background-color: #988e6f;
  display: block !important;
  border-radius: 0;
  color: #fff;
  font-family: Lato,sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0;
  margin: 15px 0 15px 0 !important;
  padding: 0;
  width: 200px;
  height: 74px;
}

@media (max-width: 1200px) {
  .reservationPanel .-form button {
    width: 150px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .reservationPanel .-form button {
    margin: 0 0 0 auto !important;
  }
}

.reservationPanel .-form button:before {
  border: #fff solid 1px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

/* EVENTS CALENDAR */
div.ba-calendar {
  background-color: #c1c0b8;
  border: 5px solid #555;
  color: #666666;
  display: block;
  margin: 0 0 20px 0;
  padding: 0;
  position: relative;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 240px;
}

div.ba-calendasr.fixed {
  position: fixed;
  top: 150px;
  z-index: 50;
}

div.ba-calendar table {
  background-color: #ede6df !important;
  border: 1px solid #c1c0b8 !important;
  border-collapse: separate;
  margin: 0;
  width: 100%;
}

div.ba-calendar table th {
  background-color: #819097;
  border: 1px solid #819097 !important;
  border-bottom: 1px solid #c1c0b8 !important;
  color: #FFF;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  text-transform: none;
}

div.ba-calendar table td {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

div.ba-calendar table td.calendarDayHeading {
  background-color: #819097 !important;
  border: 1px solid #819097 !important;
  color: #ede6df !important;
  height: 10px;
  padding: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

div.ba-calendar table td.calendarCell {
  background-color: #c1c0b8 !important;
  border: 1px solid #819097 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #555 !important;
  width: 14.28%;
}

div.ba-calendar table td.calendarCell.-with-event {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #a38461 !important;
  border: 1px solid #a38461 !important;
  border-left: 1px solid #806341 !important;
  border-top: 1px solid #806341 !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #806341 !important;
}

div.ba-calendar table td.calendarCell.-with-event a {
  color: #ede6df !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarCell.-with-event a:hover {
  color: #FFF !important;
}

div.ba-calendar table td.calendarToday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

div.ba-calendar table td.calendarToday a {
  color: #f5f6f7 !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarToday a:hover {
  color: #FFF !important;
}

/* calendar root element */
#calroot {
  /* place on top of other elements. set a higher value if nessessary */
  background-color: #988e6f;
  border: 5px solid #988e6f;
  color: #666666;
  display: none;
  margin: -15px 0 0 0;
  padding: 1px;
  position: absolute;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  top: 290px;
  width: 252px;
  z-index: 600;
}

body.ba-tpl-index #calroot {
  position: fixed !important;
}

/* head. contains title, prev/next month controls and possible month/year selectors */
#calhead {
  background-color: #988e6f;
  height: 40px;
  padding: 10px 0px 0px 0px;
}

#caltitle {
  color: #FFF;
  float: left;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  width: 200px;
}

#calnext, #calprev, .calnext, .calprev {
  background-image: url("../images/left-arrow.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: block;
  float: left;
  height: 25px;
  margin: 0 0 0 10px;
  width: 11px;
}

#calnext, .calnext {
  background-image: url("../images/right-arrow.png");
  background-position: center !important;
  margin: -20px 10px 0 0;
  width: 15px;
  height: 20px;
  float: right;
}

#calprev, .calprev {
  background-position: center !important;
  width: 15px;
  height: 20px;
}

#calprev:hover, .calprev:hover {
  background-position: 0px -25px;
}

#calnext:hover, .calnext:hover {
  background-position: 0px -75px;
}

#calprev.caldisabled, #calnext.caldisabled {
  visibility: hidden;
}

/* year/month selector */
#caltitle select {
  font-size: 10px;
}

/* names of the days */
#caldays {
  /*background-color: #333;*/
  border-top: 1px solid #CCC;
  color: #fff;
  font-size: 12px;
  height: 15px;
  padding: 10px 0px 5px 0px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

#caldays span {
  display: block;
  float: left;
  font-size: 12px;
  text-align: center;
  width: 36px;
}

/* container for weeks */
#calweeks {
  margin-top: 0px;
}

/* single week */
.calweek {
  clear: left;
  height: 25px;
}

/* single day */
.calweek a {
  background-color: #e2e1e0;
  border: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #555;
  display: block;
  float: left;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 34px;
}

/* different states */
.calweek a:hover, .calfocus {
  /*color: #ab050d !important;*/
}

/* sunday */
a.calsun {
  /*color: ab050d;*/
}

/* offmonth day */
a.caloff {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caloff:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* unselecteble day */
a.caldisabled {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caldisabled:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* current date */
#calcurrent {
  background-color: #988e6f !important;
  border: 1px solid #988e6f !important;
  border-left: none !important;
  border-top: none !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #767575 !important;
}

#calcurrent:hover {
  color: #FFF !important;
}

/* today */
#caltoday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

#caltoday:hover {
  color: #FFF !important;
}

#caldays {
  height: 14px;
}

#caldays span {
  display: block;
  float: left;
  width: 33px;
  text-align: center;
}

#caldays {
  height: 28px;
  display: flex;
  width: 100%;
  padding: 5px 0px 5px 0px;
}

#caldays span {
  float: inherit;
  width: 34px;
  padding: 3px 0px;
  text-align: center;
  display: inline-block;
}

.mobileBottomPanel {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: #988e6f;
  display: none;
  padding: 0;
}

.mobileBottomPanel .item {
  background-color: #988e6f;
  padding: 7px;
  text-align: center;
  width: 100%;
}

.mobileBottomPanel .item:hover {
  cursor: pointer;
  opacity: .7;
}

.mobileBottomPanel .item a:hover {
  text-decoration: none;
}

.mobileBottomPanel .item img {
  max-width: 18px;
}

.mobileBottomPanel .item p {
  color: #fff;
  font-size: 10px;
  margin-top: 3px;
  text-transform: uppercase;
}

.mobileBottomPanel .item.book {
  background-color: #fff;
}

.mobileBottomPanel .item.book p {
  color: #988e6f;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #28487e !important;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #E35205 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #E35205 !important;
  margin-left: 13px;
  font-size: 13px !important;
  color: #28487e !important;
  margin: 2px;
  display: inline-block;
}

.navbar {
  background-color: transparent;
  transition: .2s;
  padding: 0;
}

.navbar-brand__logo {
  width: 100px;
  transition: .2s;
}

@media (min-width: 576px) {
  .navbar-brand__logo {
    width: 110px;
  }
}

@media (min-width: 768px) {
  .navbar-brand__logo {
    width: 110px;
  }
}

@media (min-width: 992px) {
  .navbar-brand__logo {
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .navbar-brand__logo {
    width: 130px;
  }
}

@media (min-width: 1440px) {
  .navbar-brand__logo {
    width: 140px;
  }
}

/* dla menu przyczepionego */
.header--fixed .navbar-collapse .nav-item .nav-link {
  color: fff;
  font-size: 12px;
  font-weight: 400;
}

.header--fixed .navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.header--fixed .navbar-collapse .nav-item--book .nav-link {
  margin: -2px 10px -6px;
}

@media (min-width: 992px) {
  .header--fixed .navbar-collapse .nav-item {
    padding: 12px 0 !important;
  }
}

/* koniec dla menu przyczepionego */
.navbar-collapse {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .navbar-collapse {
    padding: 0;
  }
}

.navbar-collapse .nav-item:nth-child(n+4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(1) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(2) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(3) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(5) {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item {
    padding: 27px 0;
  }
}

.navbar-collapse .nav-item.dropdown > a {
  padding-right: 27px !important;
  background: url("../images/downArrow.png") no-repeat top 50% right 10px;
}

.navbar-collapse .nav-item .nav-link {
  font-family: Lato,sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 2px 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 0;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
    padding: 9px 3px 5px 0 !important;
  }
}

@media (min-width: 1100px) {
  .navbar-collapse .nav-item .nav-link {
    padding: 9px 5px 5px 0 !important;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 13px;
    margin: 0 3px;
  }
}

@media (min-width: 1440px) {
  .navbar-collapse .nav-item .nav-link {
    margin: 0 5px;
    padding: 9px nn5px 5px 0 !important;
    font-size: 14px;
  }
}

.navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse .nav-item.active > .hvr-border-fade {
  box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
}

.navbar-collapse .nav-item--book {
  opacity: .93;
  transition: .18s;
  margin-bottom: 5px;
}

.navbar-collapse .nav-item--book:hover {
  opacity: 1;
}

.navbar-collapse .nav-item--book .nav-link {
  background-color: #988e6f;
  padding: 10px 15px 5px !important;
  margin: 3px 10px 0px 10px;
}

.navbar-collapse .nav-item--book .nav-link::before {
  content: '';
  position: absolute;
  border: #fff solid 1px;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item--book .nav-link {
    margin: -4px 10px;
    padding: 10px 15px !important;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0 !important;
  min-width: 400px;
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    margin-top: -5px;
    display: none;
    background-color: rgba(152, 142, 111, 0.8);
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  border-bottom: 1px solid #fff !important;
  transition: .2s;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu .nav-link {
    padding: 11px 7px 9px 7px !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border: 0 !important;
  border-bottom: 1px solid #fff !important;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border: 0 !important;
  border-bottom: 1px solid #988d6e !important;
  box-shadow: none !important;
}

.page-id-2893 .welcome,
.page-template-page-galeria .welcome {
  background: none;
}

.welcome {
  background-color: #f4f7fe;
  background: url("../images/angelhouse-napis.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.welcome .titleSection {
  padding-bottom: 0px;
  color: #988d6e;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .welcome .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .welcome .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .welcome .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .welcome .titleSection {
    padding-bottom: 0px;
  }
}

.welcome .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  text-transform: none !important;
}

@media (min-width: 576px) {
  .welcome .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .welcome .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .welcome .subtitleSection {
    font-size: 28px;
  }
}

.welcome .textWrap {
  max-width: 930px;
  margin: 0 auto;
  text-align: justify;
}

.welcome .textWrap p + p {
  padding-top: 15px;
}

@media (max-width: 992px) {
  .welcome img {
    padding-top: 30px;
  }
}

.offer {
  overflow: hidden;
}

.offer .item {
  margin-bottom: 35px;
}

.offer .item .photoWrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 220px;
}

@media (min-width: 450px) {
  .offer .item .photoWrap {
    min-height: 250px;
  }
}

@media (min-width: 576px) {
  .offer .item .photoWrap {
    min-height: 350px;
  }
}

.offer .item .textWrap .textBox {
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 15px 50px 15px;
}

.offer .item .textWrap .textBox .titleSection {
  text-shadow: 2px 2px 7px #fff;
  text-align: left;
  padding-top: 0;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .offer .item .textWrap .textBox .titleSection {
    line-height: 60px;
  }
}

.offer .item .textWrap .textBox p {
  font-size: 14px;
  text-align: justify;
}

.offer .item .textWrap .textBox .btnBox {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -60px;
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 1500px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -100px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -110px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -120px;
  }
}

@media (min-width: 1700px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -140px;
  }
}

@media (min-width: 1800px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -160px;
  }
}

@media (min-width: 768px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -60px;
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 1500px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -100px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -110px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -120px;
  }
}

@media (min-width: 1700px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -140px;
  }
}

@media (min-width: 1800px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -160px;
  }
}

.know .specialGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.know .specialGrid .item {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  border: 10px solid #fff;
  padding: 15px;
  color: #fff !important;
  width: 25%;
  text-align: center;
  height: 350px;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .know .specialGrid .item {
    justify-content: center;
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item {
    height: 400px;
  }
}

.know .specialGrid .item a {
  text-decoration: none !important;
  color: inherit !important;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.know .specialGrid .item a:hover {
  text-decoration: none !important;
  color: inherit;
}

.know .specialGrid .item h4 {
  font-family: monarcha;
  font-size: 45px;
  text-transform: uppercase;
}

.know .specialGrid .item h5 {
  font-family: monarcha;
  text-transform: uppercase;
  padding: 20px 0 15px 0;
  line-height: 25px;
}

.know .specialGrid .item h5 strong {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  letter-spacing: .3px;
  padding: 0;
}

@media (min-width: 576px) {
  .know .specialGrid .item h5 strong {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .know .specialGrid .item h5 strong {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .know .specialGrid .item h5 strong {
    font-size: 52px;
  }
}

.know .specialGrid .item .text {
  padding-bottom: 10px;
}

.know .specialGrid .item .text p {
  font-size: 14px;
}

@media (min-width: 768px) {
  .know .specialGrid .item .text p {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  .know .specialGrid .item .text p {
    font-size: 15px;
  }
}

.know .specialGrid .item .iconBox {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .know .specialGrid .item .iconBox {
    width: 150px;
    height: 150px;
  }
}

.know .specialGrid .item .iconBox img {
  width: 140px;
  transition: 1.3s;
}

@media (min-width: 576px) {
  .know .specialGrid .item .iconBox img {
    width: 150px;
  }
}

.know .specialGrid .item:hover .iconBox img {
  transform: rotateY(360deg);
}

.know .specialGrid .item--1 {
  justify-content: center;
  height: 150px;
}

.know .specialGrid .item--1 h4 {
  font-size: 25px;
}

@media (min-width: 576px) {
  .know .specialGrid .item--1 h4 {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .know .specialGrid .item--1 h4 {
    font-size: 45px;
  }
}

@media (min-width: 768px) {
  .know .specialGrid .item--1 {
    border-right: 0;
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item--1 {
    height: 400px;
  }
}

.know .specialGrid .item--2 {
  background: url("../images/know_photo1.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
}

@media (min-width: 768px) {
  .know .specialGrid .item--2 {
    height: 360px;
  }
}

@media (min-width: 1440px) {
  .know .specialGrid .item--2 {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .know .specialGrid .item--2 {
    border-left: 0;
  }
}

.know .specialGrid .item--8 {
  padding: 0;
  justify-content: space-between;
}

.know .specialGrid .item--8 a {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: space-between;
  flex-direction: column;
}

.know .specialGrid .item--8 .photo {
  background: url("../images/know_lobby.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 60%;
}

.know .specialGrid .item--8 .text {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.know .specialGrid .item--8 .text h5 {
  padding-left: 15px;
  padding-right: 15px;
}

.newsletter {
  width: 100% !important;
  background: #156f93;
  color: #fff;
  padding-bottom: 60px;
}

.newsletter h5 {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  color: #fff;
  padding-bottom: 5px;
  text-align: center;
}

@media (min-width: 576px) {
  .newsletter h5 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .newsletter h5 {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .newsletter h5 {
    font-size: 52px;
  }
}

.newsletter h6 {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  font-family: 'amazoneplregular';
  color: #fff;
  padding-bottom: 25px;
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 576px) {
  .newsletter h6 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .newsletter h6 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .newsletter h6 {
    font-size: 28px;
  }
}

.newsletter p {
  font-size: 11px;
}

@media (min-width: 576px) {
  .newsletter p {
    font-size: 12px;
  }
}

.newsletter label {
  font-size: 10px;
  line-height: 13px;
  color: #fff;
}

.newsletter .safe {
  color: #1d1d1b;
  font-size: 12px;
  padding-top: 14px;
}

@media (min-width: 576px) {
  .newsletter .safe {
    font-size: 14px;
  }
}

.newsletter input[type=text],
.newsletter input[type=email] {
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid #fff;
  height: auto;
  padding: 5px;
  transition: .3s;
  margin-bottom: 5px;
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  color: #fff;
}

@media (min-width: 768px) {
  .newsletter input[type=text],
  .newsletter input[type=email] {
    padding: 4.5px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .newsletter input[type=text],
  .newsletter input[type=email] {
    padding: 5.5px 10px;
    font-size: 15px;
  }
}

.newsletter input[type=text]:focus,
.newsletter input[type=email]:focus {
  padding-left: 13px;
  box-shadow: none !important;
}

.newsletter div.wpcf7 .ajax-loader {
  position: absolute;
  margin-top: 8px;
}

.newsletter .wpcf7-form-control-wrap {
  position: static;
  width: 100%;
}

.newsletter .wpcf7-form-control-wrap span.wpcf7-not-valid-tip {
  width: 100%;
  font-size: 12px;
}

.newsletter .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
.newsletter div.wpcf7-mail-sent-ng, .newsletter div.wpcf7-aborted {
  border: none;
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0px;
}

.newsletter div.wpcf7-mail-sent-ok {
  border: none !important;
  color: #398f14 !important;
  text-align: center !important;
  font-weight: bold !important;
  margin: 10px 0px !important;
}

.newsletter .btnBox input[type=checkbox] {
  opacity: 0;
}

.newsletter .formWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 35px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .newsletter .formWrap {
    padding: 20px;
  }
}

@media (min-width: 1400px) {
  .newsletter .formWrap {
    padding: 25px 35px;
  }
}

.newsletter .formWrap h5 {
  color: #fff;
}

.newsletter .formWrap input[type=submit] {
  border: 1px solid #fff;
  background: none;
  padding: 10px;
}

.newsletter .formWrap input[type=submit]:hover {
  box-shadow: none !important;
  color: #156f93 !important;
  background: #fff;
  border: 1px solid #156f93 !important;
  padding: 10px !important;
}

.newsletter .agree {
  margin-top: -11px;
  margin-bottom: 10px;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 35px;
  height: 2px;
  background-color: #fff;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  margin-top: -20px;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

.listOfferTextSlider .item {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item {
    margin-bottom: 90px;
  }
}

.listOfferTextSlider .item .photoWrap {
  margin-top: 7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: content-box;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item .photoWrap {
    margin-top: 0;
  }
}

.listOfferTextSlider .item .photoWrap,
.listOfferTextSlider .item .photoWrap .slide {
  min-height: 220px;
}

@media (min-width: 450px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 250px;
  }
}

@media (min-width: 576px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 350px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 400px;
  }
}

@media (min-width: 1400px) {
  .listOfferTextSlider .item .photoWrap,
  .listOfferTextSlider .item .photoWrap .slide {
    min-height: 450px;
  }
}

.listOfferTextSlider .item .textWrap .textBox {
  margin: 0 auto;
  padding: 15px 0 15px 0;
}

.listOfferTextSlider .item .textWrap .textBox .icon {
  width: 50px;
}

.listOfferTextSlider .item .textWrap .textBox .titleSection {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 600px;
  text-transform: uppercase;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  font-size: 22px;
}

@media (min-width: 576px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 768px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection {
    line-height: 60px;
  }
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox .titleSection.medium {
    line-height: 50px;
  }
}

.listOfferTextSlider .item .textWrap .textBox .boxInfo p {
  font-size: 12px;
}

@media (min-width: 576px) {
  .listOfferTextSlider .item .textWrap .textBox .boxInfo p {
    font-size: 14px;
  }
}

.listOfferTextSlider .item .textWrap .textBox p {
  font-size: 14px;
  text-align: justify;
}

@media (min-width: 1200px) {
  .listOfferTextSlider .item .textWrap .textBox p {
    font-size: 16px;
  }
}

.listOfferTextSlider .item .textWrap .textBox p + p {
  padding-top: 15px;
}

.listOfferTextSlider .item .btnBox {
  padding-top: 30px;
}

.listOfferTextSlider .item .btnBox .linkIcon {
  color: #28487e;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  color: #eaeaea;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item .btnBox .linkIcon {
    font-size: 18px;
  }
}

.listOfferTextSlider .item .btnBox .linkIcon:hover, .listOfferTextSlider .item .btnBox .linkIcon:active, .listOfferTextSlider .item .btnBox .linkIcon:focus {
  text-decoration: none;
}

.listOfferTextSlider .item .btnBox .linkIcon:hover > img, .listOfferTextSlider .item .btnBox .linkIcon:active > img, .listOfferTextSlider .item .btnBox .linkIcon:focus > img {
  transform: scale(1.02);
}

.listOfferTextSlider .item:nth-of-type(odd) .photoWrap {
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap {
  order: 1;
}

@media (min-width: 992px) {
  .listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox {
    padding-right: 70px;
  }
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox .titleSection {
  text-align: left;
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .textBox .head {
  justify-content: flex-start;
}

.listOfferTextSlider .item:nth-of-type(odd) .textWrap .boxInfo {
  margin-right: 20px;
  order: 1;
}

.listOfferTextSlider .item:nth-of-type(odd) .btnBox {
  order: 3;
}

.listOfferTextSlider .item:nth-of-type(even) .photoWrap {
  order: 2;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item:nth-of-type(even) .photoWrap {
    order: 1;
  }
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap {
  order: 1;
}

@media (min-width: 768px) {
  .listOfferTextSlider .item:nth-of-type(even) .textWrap {
    order: 2;
  }
}

@media (min-width: 992px) {
  .listOfferTextSlider .item:nth-of-type(even) .textWrap .textBox {
    padding-left: 70px;
  }
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap .textBox .titleSection {
  text-align: left;
  order: 1;
}

.listOfferTextSlider .item:nth-of-type(even) .textWrap .boxInfo {
  margin-left: 20px;
  order: 2;
}

.listOfferTextSlider .item:nth-of-type(even) .btnBox {
  order: 3;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot {
  opacity: 1;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot span {
  background-color: #eaeaea;
  opacity: .8;
  margin: 0 5px;
}

.listOfferTextSlider .owl-theme .owl-dots .owl-dot.active span,
.listOfferTextSlider .owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
}

.listOfferTextSlider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .listOfferTextSlider .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.boxInfo {
  background-color: #E35205;
  display: inline-block;
  margin: 3px 0 8px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.boxInfo p {
  font-size: 24px;
  color: #28487e;
  font-weight: bold;
}

.specialForm .titleSection {
  padding-bottom: 0;
  color: #0a7484;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .specialForm .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .specialForm .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .specialForm .titleSection {
    font-size: 52px;
  }
}

.specialForm h5 {
  padding-bottom: 25px;
  text-transform: uppercase;
  color: #eaeaea;
  font-size: 16px;
  text-transform: lowercase;
}

.specialForm .form-group {
  margin-bottom: 25px;
}

.specialForm input, .specialForm textarea {
  box-shadow: 0 !important;
  padding: 10px 10px;
  transition: .3s;
  color: #998e70 !important;
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #998e70;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 300;
  font-family: Lato,sans-serif;
  text-transform: uppercase;
  background-color: #e2e1df;
  transition: color 0.3s, box-shadow 0.3s;
}

.specialForm input:focus, .specialForm textarea:focus {
  box-shadow: none !important;
  border-color: #988e6f;
}

.specialForm .form-control:focus {
  background-color: #EDECEB;
}

.specialForm .wpcf7-submit {
  display: block;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: Lato,sans-serif;
  text-transform: uppercase;
  background-color: #998e70;
  color: #fff !important;
}

.specialForm .wpcf7-submit:hover {
  background-color: #a69b7c;
}

.specialForm .form-check-label {
  color: #998e70 !important;
}

.specialForm .checkWrap .form-group {
  margin-bottom: 7px;
}

.specialForm a {
  color: #000;
  text-transform: uppercase;
  text-decoration: underline;
}

.specialForm .agree {
  display: flex;
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm .agree {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree {
    font-size: 14px;
  }
}

.specialForm .agree p {
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm .agree p {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree p {
    font-size: 14px;
  }
}

.specialForm .showFormPanel {
  padding-top: 8px;
  padding-bottom: 15px;
}

.specialForm .showFormPanel .blueLine {
  width: 100%;
  height: 2px;
  background-color: #8e9ba4;
}

.specialForm .showFormPanel img {
  margin-top: -32px;
  position: relative;
  z-index: 3;
}

.specialForm .showFormPanel img:hover {
  cursor: pointer;
}

.specialForm .showFormPanel .showFormText {
  color: #28487e;
  font-size: 13px;
  margin-top: -6px;
}

.specialForm .formWrap {
  display: none;
}

.specialForm .otherInfo p {
  color: #28487e;
  font-size: 13px;
}

.specialForm span.wpcf7-not-valid-tip {
  font-size: 13px !important;
  margin-left: 15px;
  padding-right: 15px;
}

.specialForm div.wpcf7-validation-errors, .specialForm div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.specialForm .form-check-input {
  margin-top: -11px;
}

.specialForm .form-check-input:hover {
  cursor: pointer;
}

.specialForm .div.wpcf7-mail-sent-ok {
  color: #398f14;
  border: none;
  text-align: center;
}

.specialForm div.wpcf7-response-output {
  margin-top: 0 !important;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.saleContact h5 {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  color: #eaeaea;
  text-transform: uppercase;
  padding: 15px 0 10px 0;
}

@media (min-width: 576px) {
  .saleContact h5 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .saleContact h5 {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .saleContact h5 {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .saleContact h5 {
    padding: 30px 0 20px 0;
  }
}

.saleContact .item {
  padding-top: 15px;
  font-size: 14px;
}

.saleContact .item p {
  font-size: 14px;
}

.saleContact .item a {
  color: #212529;
}

.saleContact .item + .item {
  margin-top: 25px;
}

@media (min-width: 576px) {
  .saleContact .item + .item {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .saleContact .item + .item {
    margin-top: 0;
  }
}

.banners {
  padding-top: 35px;
  padding-bottom: 15px;
}

.banners .item {
  height: 250px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banners .item:hover .textWrap {
  opacity: 1;
}

.banners .item:hover .title {
  opacity: 0;
}

@media (min-width: 365px) {
  .banners .item {
    height: 350px;
  }
}

.banners .item .title {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .4s;
  opacity: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.banners .item .title h5 {
  background-color: #E35205;
  color: #fff;
  padding: 15px 15px;
  font-size: 18px;
}

@media (min-width: 576px) {
  .banners .item .title h5 {
    padding: 15px 25px;
  }
}

@media (min-width: 768px) {
  .banners .item .title h5 {
    font-size: 20px;
  }
}

.banners .item .textWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  opacity: 0;
  transition: .4s;
}

.banners .item .textWrap .textBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 25px;
  text-align: center;
}

.banners .item .textWrap .textBox p {
  color: #fff;
}

.banners .item .textWrap .textBox .btnBox {
  margin-top: 20px;
}

.banners .item .textWrap .textBox .btnBox a {
  font-size: 15px;
}

.tableBox {
  margin: 0 auto;
  overflow-x: scroll;
  font-size: 14px;
}

@media (min-width: 992px) {
  .tableBox {
    font-size: 15px;
    overflow-x: hidden;
  }
}

@media (min-width: 1400px) {
  .tableBox {
    font-size: 17px;
  }
}

.tableBox .table {
  text-align: center;
  color: #eaeaea;
}

.tableBox .table thead th {
  vertical-align: middle;
}

.tableBox .table thead th p {
  min-height: 50px;
  padding-top: 8px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .tableBox .table thead th p {
    font-size: 14px;
  }
}

.tableBox .table .title {
  font-weight: 600;
  text-align: left;
}

.tableBox .table tbody th {
  vertical-align: middle;
}

.tableBox .table tbody td {
  vertical-align: middle;
  font-size: 15px;
}

@media (min-width: 576px) {
  .tableBox .table tbody td {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .tableBox .table tbody td {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .tableBox .table tbody td {
    font-size: 18px;
  }
}

.tableBox .table tbody .full {
  background-color: #9BCBEB;
  text-transform: uppercase;
  padding-top: 19px;
  padding-bottom: 20px;
}

.tableBox .table tbody .leftTitle {
  text-align: left;
  font-family: monarcha;
}

.tableBox .table tbody .iconWrap {
  width: 50px;
  text-align: right;
  margin-left: auto;
  padding-left: 8px;
}

@media (min-width: 992px) {
  .tableBox .table tbody .iconWrap {
    width: 70px;
    margin-right: 20px;
  }
}

.tableBox .table tbody .iconWrap img {
  width: 50px;
}

@media (min-width: 992px) {
  .tableBox .table tbody .iconWrap img {
    width: 70px;
  }
}

.table-bordered, .table-bordered td, .table-bordered th {
  border-color: #eaeaea;
}

.table thead th {
  border-bottom: 1px solid #eaeaea;
}

.table td, .table th {
  padding: .99rem .5rem;
}

@media (min-width: 576px) {
  .table td, .table th {
    padding: .99rem .75rem;
  }
}

.wptripadvisor_t1_A_8 {
  display: none;
}

.wptripadvisor_t1_SPAN_5,
#wprev-slider-1 .wprev_preview_bg1_T1::after {
  display: none;
}

#wprev-slider-1 .wprev_preview_tcolor2_T1 {
  display: none !important;
}

a.wprs_rd_more {
  font-weight: bold;
}

.opinions {
  background-color: #f4f7fe;
}

.opinions .titleSection {
  padding-bottom: 0px;
  color: #887846;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .opinions .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .opinions .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .opinions .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .opinions .titleSection {
    padding-bottom: 0px;
  }
}

.opinions .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .opinions .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .opinions .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .opinions .subtitleSection {
    font-size: 28px;
  }
}

.opinions-slider-item {
  padding: 10px 60px;
}

@media (min-width: 992px) {
  .opinions-slider-item {
    padding: 10px 50px;
  }
}

.opinions-slider-item__logo {
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.opinions-slider-item__logo img {
  margin: 0 auto;
}

.opinions-slider-item__text {
  text-align: justify;
  padding: 12px 0;
  max-width: 930px;
  margin: 0 auto;
}

.opinions-slider-item__author {
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
}

.opinions-slider-item__author > * {
  font-weight: 600;
}

.opinions-slider .slick-arrow {
  width: 30px;
  height: 30px;
  background-size: cover;
  z-index: 22;
  font-size: 0 !important;
  opacity: .9;
  transition: .2s;
}

@media (min-width: 992px) {
  .opinions-slider .slick-arrow {
    width: 60px;
    height: 60px;
  }
}

.opinions-slider .slick-arrow:hover {
  opacity: 1;
}

.opinions-slider .slick-arrow:before {
  content: '';
}

.opinions-slider .slick-prev {
  background-image: url("../images/opinions_prev.jpg");
  left: 5px;
}

.opinions-slider .slick-next {
  background-image: url("../images/opinions_next.png");
  right: 5px;
}

.iconsColorWrap .item {
  height: 230px;
  background-color: #28487e;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconsColorWrap .item__icon {
  height: auto;
  max-width: 100px;
  max-height: 100px;
  transition: 1.3s;
}

.iconsColorWrap .item__title {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
  font-weight: 600;
}

.iconsColorWrap .item--1 {
  background-color: #eaeaea;
}

.iconsColorWrap .item--3 {
  background-color: #9BCBEB;
}

.iconsColorWrap .item--3 .item__icon {
  margin-top: 40px;
  max-width: 155px;
  max-height: 50px;
}

.iconsColorWrap .item--3 .item__title {
  color: #eaeaea;
}

.iconsColorWrap .item--4 {
  background-color: #988e6f;
}

.iconsColorWrap .item--6 {
  background-color: #eaeaea;
}

.iconsColorWrap .item:hover .item__icon {
  transform: rotateY(360deg) !important;
}

.udogodnienia {
  background: #988e6f;
  color: #fff;
  padding-top: 35px;
  padding-bottom: 43px;
  overflow: hidden;
}

.udogodnienia .titleSection {
  padding-bottom: 0px;
  color: #fff;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .udogodnienia .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .udogodnienia .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .udogodnienia .titleSection {
    font-size: 52px;
  }
}

.udogodnienia .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  color: #fff;
  padding-bottom: 35px;
}

@media (min-width: 576px) {
  .udogodnienia .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .udogodnienia .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .udogodnienia .subtitleSection {
    font-size: 28px;
  }
}

.udogodnienia .udogodnieniaText p {
  color: #fff;
}

.udogodnienia .icons {
  text-align: center;
}

.udogodnienia .icons img {
  height: 30px;
  width: auto;
}

@media (min-width: 576px) {
  .udogodnienia .icons img {
    height: 70px;
  }
}

.GaleriaBox {
  padding-top: 17px;
  background-color: #fff;
}

.GaleriaBox .titleSection {
  padding-bottom: 0px;
  color: #988d6e;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .GaleriaBox .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .GaleriaBox .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .GaleriaBox .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .GaleriaBox .titleSection {
    padding-bottom: 0px;
  }
}

.GaleriaBox .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .GaleriaBox .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .GaleriaBox .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .GaleriaBox .subtitleSection {
    font-size: 28px;
  }
}

.GaleriaBox .GaleriaBoxTitleBackground {
  background-color: #e2e1e0;
}

.GaleriaBox .GaleriaBoxSliderBackground {
  background-color: #fff;
}

.galeriabox-slider {
  /* @include overlay(#000, 0.0); */
}

.galeriabox-slider .galeriabox-slider__slide {
  padding: 0 10px;
}

.galeriabox-slider .galeriabox-slider__slide .galeriabox-slider__media {
  position: relative;
}

.galeriabox-slider .galeriabox-slider__slide .galeriabox-slider__media img {
  height: 350px;
}

@media (min-width: 576px) {
  .galeriabox-slider .galeriabox-slider__slide .galeriabox-slider__media img {
    height: 500px;
  }
}

@media (min-width: 992px) {
  .galeriabox-slider .galeriabox-slider__slide .galeriabox-slider__media img {
    max-height: 500px;
  }
}

@media (min-width: 1500px) {
  .galeriabox-slider .galeriabox-slider__slide .galeriabox-slider__media img {
    height: auto;
    max-height: 600px;
  }
}

.galeriabox-slider .galeriabox-slider__slide.slick-center.slick-center .overlay {
  background-color: transparent !important;
}

.galeriabox-slider .slick-dots li button {
  color: #988e6f;
  background: #988e6f;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.galeriabox-slider .slick-dots li {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.galeriabox-slider .slick-arrow {
  position: absolute;
  z-index: 2000;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 45px;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 0;
  cursor: pointer;
  opacity: .7;
  transition: .2s;
  background-size: cover;
}

.galeriabox-slider .slick-arrow::before {
  content: "" !important;
}

@media (min-width: 576px) {
  .galeriabox-slider .slick-arrow {
    width: 26px;
    height: 45px;
  }
}

.galeriabox-slider .slick-arrow:hover {
  opacity: 1;
}

.galeriabox-slider .slick-arrow.slick-prev {
  left: 3%;
  background-image: url("../images/slider-left.png");
}

@media (min-width: 576px) {
  .galeriabox-slider .slick-arrow.slick-prev {
    left: 3%;
  }
}

.galeriabox-slider .slick-arrow.slick-next {
  right: 3%;
  background-image: url("../images/slider-right.png");
}

@media (min-width: 576px) {
  .galeriabox-slider .slick-arrow.slick-next {
    right: 3%;
  }
}

.KrakowParallax .parallax {
  /* The image used */
  background-image: url("../images/krakow.jpg");
  /* Set a specific height */
  min-height: 700px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.KrakowParallax .KrakowTxtBox {
  display: block;
  width: 500px;
  height: 500px;
  background-color: #fafafa;
  color: #575758;
  margin-top: 10%;
  margin-bottom: 10%;
}

.KrakowParallax .KrakowTxtBox p {
  padding: 10px 50px;
  text-align: left;
}

@media (max-width: 576px) {
  .KrakowParallax .KrakowTxtBox p {
    font-size: 10px;
  }
}

.KrakowParallax .KrakowTxtBox .titleSection {
  padding-bottom: 0px;
  color: #28487e;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .KrakowParallax .KrakowTxtBox .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .KrakowParallax .KrakowTxtBox .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .KrakowParallax .KrakowTxtBox .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .KrakowParallax .KrakowTxtBox .titleSection {
    padding-bottom: 0px;
    margin-top: 30px;
  }
}

.KrakowParallax .KrakowTxtBox .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  padding-bottom: 20px;
}

@media (min-width: 576px) {
  .KrakowParallax .KrakowTxtBox .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .KrakowParallax .KrakowTxtBox .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .KrakowParallax .KrakowTxtBox .subtitleSection {
    font-size: 28px;
  }
}

.container-fluid-paralax {
  width: 100% !important;
}

.listOfferTextSlider {
  padding-bottom: 0;
}

.apartamenty-slider {
  padding-bottom: 0;
}

.apartamenty-slider .titleSection {
  padding-bottom: 0px;
  color: #988d6e;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .apartamenty-slider .titleSection {
    padding-bottom: 0px;
  }
}

@media (min-width: 576px) {
  .apartamenty-slider .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .apartamenty-slider .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .apartamenty-slider .titleSection {
    font-size: 52px;
  }
}

.apartamenty-slider .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .apartamenty-slider .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .apartamenty-slider .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .apartamenty-slider .subtitleSection {
    font-size: 28px;
  }
}

.apartamenty-slider .item {
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  /* @include overlay(#000, 0.0); */
  display: none;
  display: none;
}

@media (min-width: 576px) {
  .apartamenty-slider .item {
    height: 400px;
  }
}

@media (min-width: 768px) {
  .apartamenty-slider .item {
    height: 500px;
  }
}

@media (min-width: 992px) {
  .apartamenty-slider .item {
    height: 550px;
  }
}

@media (min-width: 1200px) {
  .apartamenty-slider .item {
    height: 742px;
  }
}

@media (min-width: 1400px) {
  .apartamenty-slider .item {
    height: 742px;
  }
}

@media (min-width: 1600px) {
  .apartamenty-slider .item {
    height: 742px;
  }
}

.apartamenty-slider .item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .apartamenty-slider .item.onMobile {
    display: none;
  }
}

.apartamenty-slider .item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .apartamenty-slider .item.onDesktop {
    display: block;
  }
}

.apartamenty-slider .item.onMobile.onDesktop {
  display: block;
}

.apartamenty-slider .item .textBox {
  position: absolute;
  width: 100%;
  bottom: 15%;
  padding-left: 15%;
  padding-right: 15%;
}

.apartamenty-slider .item .textBox .textBoxBG {
  background-color: #fff;
  margin: 0 auto;
  padding: 20px 25px;
  min-height: 200px;
}

.apartamenty-slider .item .textBox .textBoxBG .titleOffer {
  font-size: 15px;
  padding-bottom: 10px;
  font-family: Lato,sans-serif;
  color: #0b7284;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer {
    font-size: 15px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1280px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer {
    font-size: 17px;
  }
}

@media (min-width: 1350px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer {
    font-size: 19px;
  }
}

@media (min-width: 1460px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer {
    font-size: 21px;
  }
}

.apartamenty-slider .item .textBox .textBoxBG .titleOffer strong {
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer strong {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer strong {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .apartamenty-slider .item .textBox .textBoxBG .titleOffer strong {
    font-size: 52px;
  }
}

.apartamenty-slider .item .textBox .textBoxBG h6.subTitleOffer {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 10px;
}

@media (max-width: 992px) {
  .apartamenty-slider .item .textBox .textBoxBG h6.subTitleOffer {
    font-size: 15px;
  }
}

@media (min-width: 1350px) {
  .apartamenty-slider .item .textBox .textBoxBG h6.subTitleOffer {
    font-size: 13px;
  }
}

@media (min-width: 1460px) {
  .apartamenty-slider .item .textBox .textBoxBG h6.subTitleOffer {
    font-size: 17px;
  }
}

.apartamenty-slider .item .textBox .textBoxBG .STRZmore {
  padding-top: 20px;
  padding-bottom: 20px;
}

.apartamenty-slider .item .textBox .textBoxBG .STRZmore img {
  width: 42px;
  height: 16px;
  margin-left: 20px;
}

.apartamenty-slider .item .textBox .textBoxBG .STRZmore a {
  text-transform: uppercase;
  color: #666666;
}

.apartamenty-slider .item .textBox .textBoxBG .STRZmore a img {
  display: unset;
}

.apartamenty-slider .item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .apartamenty-slider .item.onMobile {
    display: none;
  }
}

.apartamenty-slider .item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .apartamenty-slider .item.onDesktop {
    display: block;
  }
}

.apartamenty-slider .item.onMobile.onDesktop {
  display: block;
}

.apartamenty-slider .owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.apartamenty-slider .owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%);
}

.apartamenty-slider .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
  background-color: #fff;
}

.apartamenty-slider .owl-theme .owl-dots .owl-dot.active span, .apartamenty-slider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #D6D6D6;
}

.apartamenty-slider .owl-carousel .owl-nav button.owl-prev,
.apartamenty-slider .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .apartamenty-slider .owl-carousel .owl-nav button.owl-prev,
  .apartamenty-slider .owl-carousel .owl-nav button.owl-next {
    margin: 20px;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .apartamenty-slider .owl-carousel .owl-nav button.owl-prev,
  .apartamenty-slider .owl-carousel .owl-nav button.owl-next {
    width: 60px;
    height: 60px;
  }
}

.apartamenty-slider .owl-carousel .owl-nav button.owl-prev:hover,
.apartamenty-slider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.apartamenty-slider .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left.png");
}

.apartamenty-slider .owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right.png");
}

.reservation {
  padding-top: 0px;
}

.pageRooms .titleSection {
  padding-bottom: 0px;
  color: #988d6e;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .pageRooms .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .pageRooms .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .pageRooms .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .pageRooms .titleSection {
    padding-bottom: 0px;
  }
}

.pageRooms .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  text-transform: none !important;
}

@media (min-width: 576px) {
  .pageRooms .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .pageRooms .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .pageRooms .subtitleSection {
    font-size: 28px;
  }
}

.pageRooms .titleSectionPoznajApartamenty {
  margin: 10px 0 0;
  text-transform: unset;
}

@media (min-width: 576px) {
  .pageRooms .titleSectionPoznajApartamenty {
    margin: 20px 0 0;
  }
}

@media (min-width: 768px) {
  .pageRooms .titleSectionPoznajApartamenty {
    margin: 20px 0 30px;
  }
}

@media (min-width: 992px) {
  .pageRooms .titleSectionPoznajApartamenty {
    margin: 40px 0 60px;
  }
}

.pageRooms .nazwa {
  font-size: 22px;
  @includ headFontLight;
  margin: 40px 0 10px 0;
  text-transform: uppercase;
  color: #0b7284;
}

@media (min-width: 576px) {
  .pageRooms .nazwa {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .pageRooms .nazwa {
    font-size: 34px;
  }
}

@media (min-width: 992px) {
  .pageRooms .nazwa {
    font-size: 36px;
  }
}

.pageRooms .opiswyrozniony {
  margin: 20px 0;
  min-height: 234px;
  height: auto;
}

.pageRooms .opiswyrozniony div {
  padding: 20px;
}

.pageRooms .opiswyrozniony p {
  font-size: 15px;
}

.pageRooms .opiswyrozniony.opisbg0 {
  color: #fff;
  background-color: #312613;
}

.pageRooms .opiswyrozniony.opisbg1 {
  color: #fff;
  background-color: #6b8e4c;
}

.pageRooms .opiswyrozniony.opisbg2 {
  color: #fff;
  background-color: #e9a126;
}

.pageRooms .opiswyrozniony.opisbg3 {
  color: #fff;
  background-color: #4f505f;
}

.pageRooms .opis {
  margin: 20px 0;
}

.pageRooms .opis div {
  padding: 20px;
}

.pageRooms .opis p {
  font-size: 13px;
}

.pageRooms .roomUdogodnienia {
  /*
display: flex;
  		flex-direction: row;
*/
  text-align: center;
}

.pageRooms .roomUdogodnienia p {
  text-align: center;
  color: #a59c81;
}

.pageRooms .roomUdogodnienia .roomUdogodnieniaIkony {
  /*
display: block;
			width: 14%;
*/
}

.pageRooms .roomUdogodnienia img {
  width: 20px;
  height: auto;
}

@media (min-width: 576px) {
  .pageRooms .roomUdogodnienia img {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .pageRooms .roomUdogodnienia img {
    width: 40px;
  }
}

.pageRooms .iloscOsob {
  color: #656565;
  text-align: center;
  @includ headFontLight;
  font-weight: 500;
  font-size: 22px;
}

@media (min-width: 992px) {
  .pageRooms .iloscOsob {
    text-align: right;
  }
}

.pageRooms .roomUdogodnieniaRow {
  margin-top: 50px;
}

.pageRooms .titleUdogodnienia {
  font-family: monarcha;
  font-weight: 700;
  color: #656565;
  font-size: 28px;
  text-align: center;
}

@media (min-width: 576px) {
  .pageRooms .titleUdogodnienia {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .pageRooms .titleUdogodnienia {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .pageRooms .titleUdogodnienia {
    font-size: 42px;
  }
}

.pageRooms .subTitleUdogodnienia {
  font-family: 'amazoneplregular';
  color: #656565;
  font-size: 18px;
  text-align: center;
}

@media (min-width: 576px) {
  .pageRooms .subTitleUdogodnienia {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .pageRooms .subTitleUdogodnienia {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .pageRooms .subTitleUdogodnienia {
    font-size: 22px;
  }
}

.pageRooms .iloscOsobRow {
  margin: 40px 0;
}

.pageRooms .iloscOsobRow button {
  font-weight: bold;
  background-color: #988e6f;
  display: unset !important;
  border-radius: 0;
  color: #fff;
  font-family: Lato,sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0;
  margin: 15px 0 15px 0 !important;
  padding: 0;
  width: 200px;
  height: 55px;
}

@media (min-width: 992px) {
  .pageRooms .iloscOsobRow button {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  .pageRooms .iloscOsobRow button {
    width: 150px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .pageRooms .iloscOsobRow button {
    margin: 0 0 0 auto !important;
  }
}

.pageRooms .iloscOsobRow button:before {
  border: #fff solid 1px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.pageRooms .wier1 {
  margin: 0;
}

.pageRooms .wier2 {
  margin: 26px 0 0 0;
}

.pageRooms .BGlast {
  background-color: #dddddd;
  padding: 20px 0 50px;
}

.pageContact .icon img {
  width: 25px;
  height: auto;
}

.pageContact .mainContent .titleSection {
  text-transform: uppercase;
}

.pageContact .mainContent .item .textWrap .textBox a {
  color: #000;
}

.pageContact .mainContent .item .icon {
  width: 50px;
}

.pageContact .mainContent .item .icon img {
  width: 50px;
}

.pageContact .mainContent .item .text p {
  padding-top: 2px !important;
  text-align: left !important;
  font-size: 15px !important;
}

.pageContact .mainContent .item .text p + p {
  padding-top: 2px;
}

.pageContact .formContact {
  background-color: #e2e1df;
}

.pageContact .locationKontakt .titleSection {
  padding-bottom: 0px;
  color: #41b9ba;
  font-family: monarcha;
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
}

@media (min-width: 576px) {
  .pageContact .locationKontakt .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .pageContact .locationKontakt .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .pageContact .locationKontakt .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .pageContact .locationKontakt .titleSection {
    padding-bottom: 0px;
  }
}

.pageContact .locationKontakt .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
  color: #676767;
}

@media (min-width: 576px) {
  .pageContact .locationKontakt .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .pageContact .locationKontakt .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .pageContact .locationKontakt .subtitleSection {
    font-size: 28px;
  }
}

.pageContact .locationKontakt .nazwa {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -10px;
}

.pageContact .locationKontakt .opis p {
  font-size: 13px;
}

.pageContact .locationKontakt .opis .icon {
  width: 50px;
}

.pageContact .locationKontakt .opis .icon img {
  width: 50px;
}

.pageContact .locationKontakt .odleglosci {
  padding: 10px 0;
  color: #42babb;
}

.pageGallery #filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pageGallery #filters .button {
  color: #988d6e;
  margin: 4px 15px;
  min-height: 35px;
  font-size: 16px;
}

@media (min-width: 992px) {
  .pageGallery #filters .button {
    font-size: 22px;
    margin: 8px 15px;
  }
}

@media (min-width: 1200px) {
  .pageGallery #filters .button {
    font-size: 25px;
  }
}

.pageGallery #filters .button::after {
  content: '';
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  margin-top: 3px;
  transition: .3s;
  display: block;
}

.pageGallery #filters .button.activeItem::after {
  opacity: 1;
}

.pageGallery #filters .button:hover {
  cursor: pointer;
}

.pageGallery .grid {
  min-height: 500px;
}

.pageGallery .grid .element-item {
  margin-bottom: 20px;
  height: auto;
  position: relative;
  overflow: hidden;
  height: 300px;
}

@media (max-width: 768px) {
  .pageGallery .grid .element-item {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .pageGallery .grid .element-item {
    width: 350px;
  }
}

@media (min-width: 1200px) {
  .pageGallery .grid .element-item {
    width: 400px;
  }
}

@media (min-width: 1400px) {
  .pageGallery .grid .element-item {
    width: 440px;
  }
}

.pageGallery .grid .element-item img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  margin: 0 20px;
}

.pageSingleOffer {
  margin-top: -8px;
}

.pageSingleOffer .item .infoBox .photo {
  height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

@media (min-width: 576px) {
  .pageSingleOffer .item .infoBox .photo {
    height: 280px;
  }
}

@media (min-width: 768px) {
  .pageSingleOffer .item .infoBox .photo {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .pageSingleOffer .item .infoBox .photo {
    height: 260px;
  }
}

@media (min-width: 1200px) {
  .pageSingleOffer .item .infoBox .photo {
    height: 500px;
  }
}

.pageSingleOffer .item .infoBox .panel {
  padding: 0 50px;
}

.pageSingleOffer .item .infoBox .panel .elementWrap {
  padding-top: 13px;
  min-height: inherit;
}

.pageSingleOffer .item .infoBox .panel .elementWrap.centerCol {
  padding-top: 13px;
}

.pageSingleOffer .item .infoBox .panel .elementWrap p {
  text-align: left;
}

.pageSingleOffer .item .infoBox .btnBox {
  margin-top: 15px;
}

.pageSingleOffer .item .infoBox .btnBox a:hover {
  color: #fff !important;
}

.pageSingleOffer .item .infoBox .panelBottom {
  padding-top: 40px;
  padding-bottom: 30px;
}

.pageSingleOffer .item .infoBox .panelBottom .btnBox {
  margin: 0;
}
