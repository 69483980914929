.header{
	 width: 100%!important;
    background-color: rgba(152, 142, 111, .8);
    transition:.3s;
    
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;

    @media(min-width:992px){
        background-color:transparent;
    }

    //// HEADER FIXED 

    &--fixed{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: rgba(152, 142, 111, .8);

        .navbar-brand{
            &__logo{
                width:80px;

                @include media(small){
                    width:85px;
                }
        
                @include media(medium){
                    width:90px;
                }
        
                @include media(large){
                    width:95px;
                }
        
                @include media(xlarge){
                    width:100px;
                }
        
                @include media(xxlarge){
                    width:110px;
                }

            }
        }

        .navbar{
            padding-top:0;
            padding-bottom:0;
        }

        .dropdown-menu{
            margin-top:0 !important;   

            @include media(large){
                background-color: rgba(152, 142, 111, .8) !important;
            }
        }

    }
    //// END HEADER FIXED 

    
}