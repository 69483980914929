.udogodnienia{
	background: $udogodnieniaBackgroundColor;
	color: $white;
    padding-top:35px;
    padding-bottom:43px;
    overflow:hidden;
    .titleSection{
        padding-bottom:0px;
		  color: $white;	
        @include headFontBold;  
    }
	 .subtitleSection{
	  		@include subtitleSection; 
		  color: $white;
		  padding-bottom: 35px;
	 }
	.udogodnieniaText p{
		  color: $white;	}
	
	.icons {
		text-align: center;
		img {
			 height: 30px;
        @media(min-width:576px){
				height: 70px;
        } 
			width: auto;
		}
	}
}