.pageRooms{
	.titleSection{	
        padding-bottom:0px;
		  color: $apartamentyGold;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
	}
	.subtitleSection{
		@include subtitleSection; 
		text-transform: none!important; 
	}
	.titleSectionPoznajApartamenty{
			margin: 10px 0 0;
		text-transform: unset;
		@media(min-width:576px){
			margin: 20px 0 0;
		}
		@media(min-width:768px){
			margin: 20px 0 30px;
		}
		@media(min-width:992px){
			margin: 40px 0 60px;
		}
	}
	.nazwa {
		font-size: 22px;
		@includ headFontLight;
		margin: 40px 0 10px 0;
		text-transform: uppercase;
		color: #0b7284;
		@media(min-width:576px){
			font-size: 30px;
		}
		@media(min-width:768px){
			font-size: 34px;
		}
		@media(min-width:992px){
			font-size: 36px;
		}
	}
	
	.opiswyrozniony {
		margin: 20px 0;
		div {
			padding: 20px;
		}
		min-height: 234px;
		height: auto;
		p {
			font-size: 15px;
		}
		&.opisbg0{
			@include RoomBG($roomsOne, $white);		
		}
		&.opisbg1{
			@include RoomBG($roomsTwo, $white);		
		}
		&.opisbg2{
			@include RoomBG($roomsThree, $white);		
		}
		&.opisbg3{
			@include RoomBG($roomsFour, $white);		
		}
	}
	.opis {
		margin: 20px 0;
		div {
			padding: 20px;
		}
		p {
			font-size: 13px;
		}
	}
	.roomUdogodnienia {
		/*
display: flex;
  		flex-direction: row;
*/
		text-align: center;
		p {
			text-align: center;
			color: #a59c81;
		}
		.roomUdogodnieniaIkony{
		  /*
display: block;
			width: 14%;
*/
		}
		img {
			width: 20px;
			height: auto;
			@media(min-width:576px){
				width: 40px;
			}
			@media(min-width:768px){
				width: 40px;
			}
		}
	}
	.iloscOsob {
		color: #656565;
		text-align: center;
		@includ headFontLight;
		font-weight: 500;
		font-size: 22px;
		@media(min-width:992px){
			text-align: right;
		}
	}
	.roomUdogodnieniaRow{
			margin-top: 50px;
	}
	 .titleUdogodnienia{
        @include titleFontBold;  
			color: #656565;
			font-size: 28px;
			text-align: center;
			@media(min-width:576px){
				font-size: 28px;
			}
			@media(min-width:768px){
				font-size: 36px;
			}
			@media(min-width:992px){
				font-size: 42px;
			}
	 }
	 .subTitleUdogodnienia{
        @include subTitleFontLight;  
		color: #656565;
			font-size: 18px;
			text-align: center;
			@media(min-width:576px){
				font-size: 18px;
			}
			@media(min-width:768px){
				font-size: 20px;
			}
			@media(min-width:992px){
				font-size: 22px;
			}
	 }
	 .iloscOsobRow{
			margin: 40px 0;
			button{
				font-weight:bold;
				background-color:$udogodnieniaBackgroundColor;
				display:unset !important;
				@media(min-width:992px){
					display:block !important;
				}
				border-radius: 0;
				color:#fff;
				@include formFont;
				font-weight: 500;
				text-transform: uppercase;
				padding: 0;
				margin: 15px 0 15px 0 !important;
				padding: 0;
				width: 200px;
				height: 55px;

			@media(max-width:1200px){
				width: 150px;
				height: 50px;
			}
			@media(min-width:1200px){
				margin:0 0 0 auto !important;
			}

			@media(min-width:1250px){
			}
			&:before {				
				border: #fff solid 1px;
				top: 5px;
				right: 5px;
				bottom: 5px;
				left: 5px;
			}

			}
	 }
	 .wier1 {
	 	margin: 0;
	 }
	 .wier2 {
	 	margin: 26px 0 0 0;
	 }
	 
	.BGlast{
		background-color: #dddddd;
		padding: 20px 0 50px;
	}
}