.container-fluid-paralax{width: 100% !important;}
.listOfferTextSlider {padding-bottom:0}
.apartamenty-slider {
	padding-bottom: 0;
	
    .titleSection{
        @media(min-width:576px){
            padding-bottom:0px;
        } 			
        padding-bottom:0px;
		  color: $apartamentyGold;	
        @include headFontBold;  
    }
	 .subtitleSection{
    	@include subtitleSection; 
	 }
	 
   .item{
        height:400px;
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        position:relative;


        @media(min-width:576px){
            height:400px;
        }

        @media(min-width:768px){
            height:500px;
        }

        @media(min-width:992px){
            height:550px;
        }

        @media(min-width:1200px){
            height:742px;
        }

        @media(min-width:1400px){
            height:742px;
        }

        @media(min-width:1600px){
            height:742px;
        }
        
        /* @include overlay(#000, 0.0); */

        display:none;

        &.onMobile{
            display:block;

            @media(min-width:768px){
                display:none;
            }
        }

        &.onDesktop{
            display:none;

            @media(min-width:768px){
                display:block;
            }
        }

        &.onMobile.onDesktop{
            display:block;
        }
        

        .textBox{
				
            position: absolute;
				width: 100%;
				bottom: 15%;
            padding-left: 15%;
            padding-right: 15%;
				
            .textBoxBG{
					background-color: #fff;
					margin: 0 auto;
					padding: 20px 25px;
			  		min-height: 200px;
					.titleOffer{
							font-size: 15px;
					    @media(max-width:992px){
						 		font-size: 17px;
					    }
					    @media(min-width:1200px){
						 		font-size: 15px;
					        padding-bottom:10px;
					    }
					    @media(min-width:1280px){
						 		font-size: 17px;
					    }
					    @media(min-width:1350px){
						 		font-size: 19px;
					    }
					    @media(min-width:1460px){
						 		font-size: 21px;
					    }
					    padding-bottom:10px;
					    @include headSpecialOffer;
						 color: $turquoise;	
						text-transform: uppercase;


					    strong{
					        @include headFontBold;
					    }
					}
					h6.subTitleOffer {
						text-transform: uppercase;
						margin-bottom: 25px;
						font-weight: 300;
						letter-spacing: 3px;
						font-size: 10px;
					    @media(max-width:992px){
						 		font-size: 15px;
					    }
					    @media(min-width:1350px){
						 		font-size: 13px;
					    }
					    @media(min-width:1460px){
						 		font-size: 17px;
					    }
					}
					.STRZmore {
						padding-top: 20px;
						padding-bottom: 20px;
						img {
							width: 42px; 
							height: 16px; 
							margin-left: 20px;
						}
						a {
							text-transform: uppercase;
							color: $specjaloffersTxt;
							img {
								display: unset;
							}
						}
					}
					}

        }

        display:none;

        &.onMobile{
            display:block;

            @media(min-width:768px){
                display:none;
            }
        }

        &.onDesktop{
            display:none;

            @media(min-width:768px){
                display:block;
            }
        }

        &.onMobile.onDesktop{
            display:block;
        }

    }

    .owl-theme .owl-nav{
        width: 100%;
        max-width:1630px;
        left:50%;
        position: absolute;
        top: 50%;
        transform:translate(-50%,-50%);
        display: flex;
        justify-content: space-between;

        height:0;
        margin-top:-40px;
    }

    .owl-theme .owl-dots{
        position: absolute;
        bottom: 10px;
        text-align: center;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%);
    }

    .owl-theme .owl-dots .owl-dot span{
        margin: 5px 4px;
        background-color: #fff;
    }

    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-color: #D6D6D6;
    }
    

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next{
        width:30px;
        height:30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 20px 5px;

        @media(min-width:576px){
            margin:20px;
            width:40px;
            height:40px;
        }

        @media(min-width:992px){
            width:60px;
            height:60px;
        }

        &:hover{
            background-color:transparent;
        }
    }

    .owl-carousel .owl-nav button.owl-prev{
        background-image: url('../images/owl-left.png');
    }

    .owl-carousel .owl-nav button.owl-next{
        background-image: url('../images/owl-right.png');
    }

}
