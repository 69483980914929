.reservationPanel{

    background-color: $ultraLightGrey;
    
    margin-bottom: 50px;
    z-index: 20;
	position: relative;
	display:none;
	padding:0;
	margin-top: -104px;

	@media(min-width:576px){
		display:block;
	}

	max-width: 800px;

	
	 .form-inline .form-group{ 
	 	display: unset!important;
		margin: 0 7px 10px;
	 }
	 
    .-form{
		 	label{
				display: block!important;
				font-size: 9px;
				clear: both;
				margin: 0;
				padding: 5px 0 0px 17px;
			}
		justify-content: center;

		h5{
			color:#fff;
			padding:5px 30px;
			@include headFontBold;
			letter-spacing:.3px;
			margin-top:4px;
			padding-left:40px;
			font-size:21px;
			text-transform: uppercase;

			width:100%;
			text-align:center;
			padding:15px 30px;
			@media(min-width:1200px){
				width:250px;
				text-align:center;
				padding:5px 30px;
			}
		}

        input, select{
            margin:0 5px;
            background-color:#fff;
            border:1px solid #d1d1d1;
            outline:0 !important;
            box-shadow: none !important;
            color: #000;
			border-radius: 0;
			padding: 0 10px 0 10px;			
			max-width: 175px;
			font-size: 12px;
			cursor:pointer;
			width: 170px;
			min-height: 35px;

			@include formFont;

			@media(min-width:767px){
				font-size: 12px;
			}

			@media(min-width:800px){
			}

			@media(min-width:870px){
				font-size: 12px;
			}

			@media(min-width:1300px){
				width: 175px;
			}

            &:focus{
                background-color:#fff;
                border:0;
                outline:0 !important;
                box-shadow: none !important;
				color:#000;
			}
			
			&.inputDateIn{
				background-image:url('../images/kalendarz.svg');
				background-repeat:no-repeat;
				background-position:90% center;
				background-size: 16px 16px;
			}

			&.inputDateOut{
				background-image:url('../images/kalendarz.svg');
				background-repeat:no-repeat;
				background-position:90% center;
				background-size: 16px 16px;
			}

			&.promocode{
				background-image:url('../images/promoCode_bg.png');
				background-repeat:no-repeat;
				background-position:90% center;
			}

		}
		
		select{
			option{
				padding:5px;
			}
			@include formFont;
		}

        button{
			font-weight:bold;
			background-color:$udogodnieniaBackgroundColor;
			display:block !important;
			border-radius: 0;
			color:#fff;
			@include formFont;
			font-weight: 500;
			text-transform: uppercase;
			padding: 0;
			margin: 15px 0 15px 0 !important;
			padding: 0;
			width: 200px;
			height: 74px;

		    @media(max-width:1200px){
				width: 150px;
				height: 50px;
			 }
			@media(min-width:1200px){
				margin:0 0 0 auto !important;
			}
			
			@media(min-width:1250px){
			}
			&:before {				
				border: #fff solid 1px;
				top: 5px;
				right: 5px;
				bottom: 5px;
				left: 5px;
			}

        }
    }

}




/* EVENTS CALENDAR */
div.ba-calendar
{
	background-color: #c1c0b8;
	border: 5px solid #555;
	color: #666666;
	display: block;
	margin: 0 0 20px 0;
	padding: 0;
	position: relative;
	text-shadow: 0 1px 0 rgba(255,255,255,0.5);
	width: 240px;
}
div.ba-calendasr.fixed
{
	position: fixed;
	top: 150px;
	z-index: 50;
}
div.ba-calendar table
{
	background-color: #ede6df !important;
	border: 1px solid #c1c0b8 !important;
	border-collapse: separate;
	margin: 0;
	width: 100%;
}
		div.ba-calendar table th
		{
			background-color: #819097;
			border: 1px solid #819097 !important;
			border-bottom: 1px solid #c1c0b8 !important;
			color: #FFF;
			font-size: 18px;
			line-height: 20px;
			margin: 0;
			padding: 10px 0;
			text-align: center;
			text-shadow: 0 1px 0 rgba(0,0,0,0.5);
			text-transform: none;
		}
		div.ba-calendar table td
		{
			background-color: #f5f6f7 !important;
			border: 1px solid #f5f6f7 !important;
			border-left: 1px solid #f5f6f7 !important;
			border-top: 1px solid #f5f6f7 !important;
			color: #c1c0b8 !important;
			cursor: default !important;
			font-size: 12px;
			line-height: 30px;
			padding: 0;
			text-align: center;
			text-shadow: 0 1px 0 rgba(255,255,255,0.5);
		}
		div.ba-calendar table td.calendarDayHeading
		{
			background-color: #819097 !important;
			border: 1px solid #819097 !important;
			color: #ede6df !important;
			height: 10px;
			padding: 0;
			text-shadow: 0 1px 0 rgba(0,0,0,0.5);
		}
		div.ba-calendar table td.calendarCell /*DIA DEL MES*/
		{
			background-color: #c1c0b8 !important;
			border: 1px solid #819097 !important;
			border-left: 1px solid #f5f6f7 !important;
			border-top: 1px solid #f5f6f7 !important;
			color: #555 !important;
			width: 14.28%;
		}
		div.ba-calendar table td.calendarCell.-with-event /*DIA CON EVENTO*/
		{
			background: -moz-linear-gradient(top, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%) !important; /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.05)), color-stop(100%,rgba(0,0,0,0.05))) !important; /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Opera 11.10+ */
			background: -ms-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* IE10+ */
			background: linear-gradient(to bottom, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important; /* IE6-9 */
			background-color: #a38461 !important;
			border: 1px solid #a38461 !important;
			border-left: 1px solid #806341 !important;
			border-top: 1px solid #806341 !important;
			color: #ede6df !important;
			text-shadow: 0 1px 0 #806341 !important;
		}
		div.ba-calendar table td.calendarCell.-with-event a
		{
			color: #ede6df !important;
			display: block;
			text-decoration: none;
		}
		div.ba-calendar table td.calendarCell.-with-event a:hover
		{
			color: #FFF !important;
		}
		div.ba-calendar table td.calendarToday /*HOY*/
		{
			background: -moz-linear-gradient(top, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%) !important; /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.05)), color-stop(100%,rgba(0,0,0,0.05))) !important; /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Opera 11.10+ */
			background: -ms-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* IE10+ */
			background: linear-gradient(to bottom, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important; /* IE6-9 */
			background-color: #555 !important;
			border: 1px solid #555 !important;
			border-left: 1px solid #2d373b !important;
			border-top: 1px solid #2d373b !important;
			color: #c1c0b8 !important;
			text-shadow: 0 1px 0 #2d373b;
		}
		div.ba-calendar table td.calendarToday a
		{
			color: #f5f6f7 !important;
			display: block;
			text-decoration: none;
		}
		div.ba-calendar table td.calendarToday a:hover
		{
			color: #FFF !important;
		}




/* calendar root element */
#calroot
{
	/* place on top of other elements. set a higher value if nessessary */
	background-color: #988e6f;
	border: 5px solid #988e6f;
	color: #666666;
	display: none;
	margin: -15px 0 0 0;
	padding: 1px;
	position: absolute;
	text-shadow: 0 1px 0 rgba(255,255,255,0.5);
	top: 290px;
	width: 252px;
	z-index: 600;
}
body.ba-tpl-index #calroot
{
	position: fixed !important;
}
/* head. contains title, prev/next month controls and possible month/year selectors */
#calhead
{
	background-color: #988e6f;
	height: 40px;
	padding: 10px 0px 0px 0px;
}
#caltitle
{
	color: #FFF;
	float: left;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	text-shadow: 0 1px 0 rgba(0,0,0,0.5);
	width: 200px;
}
#calnext, #calprev, .calnext, .calprev
{
	background-image: url('../images/left-arrow.png')/*tpa=http://hotelgrottger.pl/sprite-icons-1.png*/;
	background-position: 0px 0px;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	display: block;
	float: left;
	height: 25px;
	margin: 0 0 0 10px;
	width: 11px;
}

#calnext, .calnext{
	background-image: url('../images/right-arrow.png');
	background-position: center !important;
	margin: -20px 10px 0 0;
    width: 15px;
	height: 20px;
	float: right;
}

#calprev, .calprev{
	background-position: center !important;
    width: 15px;
	height: 20px;
}

		#calprev:hover, .calprev:hover
		{
			background-position: 0px -25px;
		}

		#calnext:hover, .calnext:hover
		{
			background-position: 0px -75px;
		}

#calprev.caldisabled, #calnext.caldisabled
{
	visibility: hidden;
}
/* year/month selector */
#caltitle select
{
	font-size: 10px;
}
/* names of the days */
#caldays
{
	/*background-color: #333;*/
	border-top: 1px solid #CCC;
	color: #fff;
	font-size: 12px;
	height: 15px;
	padding: 10px 0px 5px 0px;
	text-shadow: 0 1px 0 rgba(0,0,0,0.5);
}
#caldays span
{
	display: block;
	float: left;
	font-size: 12px;
	text-align: center;
	width: 36px;
}
/* container for weeks */
#calweeks
{
	margin-top: 0px;
}
/* single week */
.calweek
{
	clear: left;
	height: 25px;
}
/* single day */
.calweek a
{
	background-color: #e2e1e0;
	border: 1px solid #fff;
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
	color: #555;
	display: block;
	float: left;
	font-size: 12px;
	height: 28px;
	line-height: 28px;
	margin: 0px;
	padding: 0px;
	text-align: center;
	text-decoration: none;
	text-shadow: 0 1px 0 rgba(255,255,255,0.5);
	width: 34px;
}
/* different states */
.calweek a:hover, .calfocus
{
	/*color: #ab050d !important;*/
}
/* sunday */
a.calsun
{
	/*color: ab050d;*/
}
/* offmonth day */
a.caloff
{
	background-color: #f5f6f7 !important;
	border: 1px solid #f5f6f7 !important;
	color: #c1c0b8 !important;
	cursor: default !important;
}
a.caloff:hover
{
	background-color: #f5f6f7 !important;
	color: #c1c0b8 !important;
	cursor: default !important;
}
/* unselecteble day */
a.caldisabled
{
	background-color: #f5f6f7 !important;
	border: 1px solid #f5f6f7 !important;
	color: #c1c0b8 !important;
	cursor: default!important;
}
a.caldisabled:hover
{
	background-color: #f5f6f7 !important;
	color: #c1c0b8 !important;
	cursor: default!important;
}
/* current date */
#calcurrent
{
	background-color: #988e6f !important;
	border: 1px solid #988e6f !important;
	border-left: none !important;
	border-top: none !important;
	color: #ede6df !important;
	text-shadow: 0 1px 0 #767575 !important;
}
#calcurrent:hover
{
	color: #FFF !important;
}
/* today */
#caltoday
{
	background: -moz-linear-gradient(top, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%) !important; /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.05)), color-stop(100%,rgba(0,0,0,0.05))) !important; /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* IE10+ */
	background: linear-gradient(to bottom, rgba(255,255,255,0.05) 0%,rgba(0,0,0,0.05) 100%) !important; /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important; /* IE6-9 */
	background-color: #555 !important;
	border: 1px solid #555 !important;
	border-left: 1px solid #2d373b !important;
	border-top: 1px solid #2d373b !important;
	color: #c1c0b8 !important;
	text-shadow: 0 1px 0 #2d373b;
}
#caltoday:hover
{
	color: #FFF !important;
}






#caldays
{
height:14px;

}
#caldays span
{
display:block;
float:left;
width:33px;
text-align:center;
}

#caldays{
	height: 28px;
    display: flex;
    width: 100%;
	padding: 5px 0px 5px 0px;
}

#caldays span {
    float: inherit; 
    width: 34px;
    padding: 3px 0px;
    text-align: center;
    display: inline-block;
}