.specialOffers{
	background: url('../images/specjaloffersBackground.png');
	background-repeat: no-repeat;
	background-position: 0 20px; 
	background-color: $specjaloffersBackgroundColor;
    padding-top:40px;
    overflow:hidden;
    padding-bottom:50px;
	 
	 .row{
	 }
	 
    .titleSection{
        padding-bottom:0px;
		  color: $turquoise;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }
	 .subtitleSection{
        		@include subtitleSection; 
	 }


	.titleOffer{
	    padding-bottom:10px;
	    font-size:21px;
	    @include headSpecialOffer;
		  color: $turquoise;	
		text-transform: uppercase;

	    @media(min-width:768px){
	        font-size:22px;
	    }

	    @media(min-width:992px){
	        font-size:24px;
	        padding-bottom:10px;
	    }

	    strong{
	        @include headFontBold;
	    }
	}
	h6.krotkaCena {
		text-transform: uppercase;
		margin-bottom: 25px;
		font-weight: 300;
		letter-spacing: 3px;
		@media(max-width:992px){
			margin-bottom: 0; 
		}
	}
	p.krotkiTekst {
		padding-bottom: 15px;
		color: $specjaloffersTxt;
	}

	.STRZmore {
		padding-top: 20px;
		img {
			width: 42px; 
			height: 16px; 
			margin-left: 20px;
		}
		a {
		text-transform: uppercase;
		color: $specjaloffersTxt;
		}
	}
	
        .item{
			  .textWrap{
					padding-right: 50px; 
					padding-left: 50px;	
                    @media(max-width:992px){
								padding-right: 0; 
								padding-left: 0;	
                    }
					.elementWrap {
						padding: 0 70px;
					}
				}	
			  background-color: #fff;	
            //item odd (nieparzysty)
                &:nth-of-type(odd) {

                    .photoWrap{
						  padding-left:0;	
                        @media(min-width:992px){
                            order:2;
                        }
                    @media(max-width:768px){
						  		padding-left: 15px;
                    }
                    }

                    .textWrap {
					 			margin: 20px 0;
                        @media(min-width:992px){
                            order:1;
									margin: 0;
                        }
                    }
                }

            

            //item even (parzysty)
            &:nth-of-type(even) {
                .photoWrap{
						  padding-right: 0;	
                    @media(min-width:992px){
                    		order:1;
                    }
                    @media(max-width:768px){
						  		padding-right: 15px;
                    }
                }

                .textWrap {
					 		margin: 20px 0;
                    @media(min-width:992px){
                        order:2;
								margin: 0;
                    }
						  
                }                
            	}         

            // end item even (parzysty)
        }


}






