.iconsColorWrap{
    .item{
        
        height:230px;      
        background-color:$blue;
        padding:10px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        

        &__icon{
            height:auto;
            max-width: 100px;
            max-height: 100px;
            transition:1.3s;
        }

        &__title{
            text-transform: uppercase;
            color:#fff;
            font-size:16px;
            text-align: center;
            padding:20px 0;
            font-weight: 600;
        }

        &--1{
            background-color:$ultraLightGrey;
        }

        &--2{
            
        }

        &--3{
            background-color:$heavenly;
            
            .item__icon{
                margin-top:40px;
                max-width: 155px;
                max-height: 50px;
            }

            .item__title{
                color:$ultraLightGrey;
            }
        }

        &--4{
            background-color:$darkGold;
        }

        &--5{
            
        }

        &--6{
            background-color:$ultraLightGrey;
        }

        &:hover{
            
            .item__icon{
                transform: rotateY(360deg) !important;
            }
        }

    }
}