#cookie-msg{
    padding:12px !important;
    background-color:$blue !important;
    z-index: 100;

    .msg{
        font-size: 12px;
        @media(min-width: 576px){
            font-size: 14px;
        }
    }

    a{
        color:$yellow !important;
    }

    .btn-aceptar{
        background-color:$yellow !important;
        margin-left:13px;
        font-size:13px !important;
        color:$blue !important;
        margin:2px;
        display: inline-block;
    }

  }