
.KrakowParallax {
	.parallax {
	  /* The image used */
	  background-image: url('../images/krakow.jpg');

	  /* Set a specific height */
	  min-height: 700px; 

	  /* Create the parallax scrolling effect */
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
	}
	
	.KrakowTxtBox {
		display: block;
		width: 500px;
		height: 500px;
		background-color: #fafafa;
		color: $textColor; 	
		margin-top: 10%;
		margin-bottom: 10%;
			
		p {
			padding: 10px 50px;
			text-align: left;			
        @media(max-width:576px){
				font-size: 10px
        } 
		}
				 
		.titleSection{
			padding-bottom:0px;
			color: $blue;	
			@include headFontBold;  
			@media(min-width:576px){
				padding-bottom:0px;
				margin-top: 30px;
			} 
		}

		.subtitleSection{
        		@include subtitleSection; 
			padding-bottom: 20px;
		}
	}
	
}