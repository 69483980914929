$breakpoints: (
    xsmall: (min-width: 460px), 
    small: (min-width: 576px), 
    medium: (min-width: 768px), 
    large: (min-width: 992px),  
    xlarge: (min-width: 1200px), 
    xxlarge: (min-width: 1440px)
);

@mixin media($breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    @if($size) {
      @media #{$size} {
        @content;
      }
    } @else {
      @error '"#{$breakpoint}" - błąd, nie wiem co to';
    }
}  
 
@mixin RoomBG($bgcolor, $color) {
	color: $color;
	background-color: $bgcolor;
}   

//uzycie
// @include media(xsmall) {
//     font-size:18px;
// }

@mixin headReservationFont {
    font-family:  Lato,sans-serif;
}
@mixin formFont {
    font-family:  Lato,sans-serif;
}
@mixin ButtonFont {
    font-family:  Lato,sans-serif;
}

@mixin subTitleFontLight {
    font-family: 'amazoneplregular';
}


@mixin headSpecialOffer {
    font-family: Lato,sans-serif;
}

@mixin headFontLight {
    font-family: monarcha;
}

@mixin headFontMid {
    font-family:  monarcha;
	 font-weight: 500;
}

@mixin titleFontBold {
  	font-family: monarcha;
	font-weight: 700;
}

@mixin headFontBold {
  	font-family: monarcha;
	font-weight: 700;
	font-style: normal;
	font-size: 34px;
	@media(min-width:576px){
		font-size: 34px;
	}
	@media(min-width:768px){
		font-size: 38px;
	}
	@media(min-width:992px){
		font-size: 52px;
	}
}
@mixin headFontSemiBold {
  	font-family: monarcha;
	font-weight: 500;
	font-style: normal;
}

@mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
}

@mixin overlay($bgColor: #000, $bgOpacity: 1){
  .overlay{
      background:rgba($bgColor, $bgOpacity);
      position:absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
  }
}

@mixin flex-center{
  display:flex;
  align-items: center;
  justify-content: center;
}

@mixin subtitleSection {
	color: $specjaloffersTxt;
	padding-bottom: 45px;
	line-height: 35px;		
	font-size: 24px;
	@media(min-width:576px){
		font-size: 24px;
	}
	@media(min-width:768px){
		font-size: 26px;
	}
	@media(min-width:992px){
		font-size: 28px;
	}
}