.GaleriaBox {
	padding-top: 17px;
	background-color: #fff;	 
    .titleSection{
        padding-bottom:0px;
		  color: $galeriaGold;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }
	 
	 .subtitleSection{
        		@include subtitleSection; 
	 }
	 .GaleriaBoxTitleBackground{
  		background-color: $galeriaBoxBackgroundColor;
	 }
	 .GaleriaBoxSliderBackground{
  		background-color: #fff;
	 }
}
.galeriabox-slider {
    
        /* @include overlay(#000, 0.0); */
    

    .galeriabox-slider__slide {
        
        padding:0 10px;
       
        

        .galeriabox-slider__media{
            position: relative;
            img{
                height:350px;

                @media(min-width:576px){
                    height:500px;
                }

                @media(min-width:992px){
                    max-height:500px;
                }

                @media(min-width:1500px){
                    height:auto;
                    max-height: 600px;
                }
            }
        }

        &.slick-center{
            &.slick-center{
                .overlay{
                    background-color: transparent !important;
                }
            }
        }

    }

}

//slick arrow

.galeriabox-slider{
	.slick-dots {
		li button {
			color: $darkGold;
			background:$darkGold;
		    width: 50px;
		    height: 50px;
		    cursor: pointer;
		}
		li{
		    width: 50px;
		    height: 50px;
		    cursor: pointer;
		}
	}
    .slick-arrow{
        position: absolute;
        z-index: 2000;
        top:50%;
        transform:translateY(-50%);
            width:26px;
            height:45px;
        background-color:transparent;
        border:0;
        outline:0;
        font-size:0;
        cursor:pointer;
        opacity:.7;
        transition:.2s;
        background-size:cover;

        &::before{
            content:"" !important;
        }

        @media(min-width:576px){
            width:26px;
            height:45px;
        }
        
        &:hover{
            opacity: 1;
        }

        &.slick-prev{
            left:3%;
            background-image: url('../images/slider-left.png');
            @media(min-width: 576px){
                left:3%;
            }
        }

        &.slick-next{
            right:3%;
            background-image: url('../images/slider-right.png');
            @media(min-width: 576px){
                right:3%;
            }
        }
    }
    
}