// $standardHeight: 

.know{
    .specialGrid{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        
        
        .item{
            display:flex;
            align-self: center;
            justify-content: center;
            flex-direction: column;
            border:10px solid #fff;
            // background-color:$blue;

            padding:15px;
            color:#fff !important;
            width:25%;
            text-align: center;
            height:350px;
            box-sizing: border-box;
            
            @include media(small){
                justify-content: center;
                height:360px;
            }

            @include media(xxlarge){
                height:400px;
            }

            a{
                text-decoration: none !important;
                color:inherit !important;
                display:block;
                width:100%;
                height:100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 5;
                
                &:hover{
                    text-decoration: none !important;
                    color:inherit;
                }
            }
            
            h4{
                @include headFontLight;
                font-size:45px;
                text-transform: uppercase;
            }

            h5{
                @include headFontLight;
                text-transform: uppercase;
                padding:20px 0 15px 0;
                line-height: 25px;

                strong{
                    @include headFontBold;
                    letter-spacing: .3px;
                    padding:0;
                }
            }

            .text{
                padding-bottom:10px;

                @media(min-width:1200px){
                    
                }

                p{
                    font-size:14px;
                }
                
                @media(min-width:768px){
                    p{
                        font-size:14px;
                    }
                }

                
                @media(min-width:1400px){
                    p{
                        font-size:15px;
                    }
                }
            }

            .iconBox{
                width:140px;
                height:140px;
                @include flex-center;
                margin:0 auto;

                @include media(small){
                    width:150px;
                    height:150px;
                }

                img{
                    width:140px;
                    transition:1.3s;

                    @include media(small){
                        width:150px;
                    }
                }
            }

            &:hover{
                .iconBox{
                    img{
                       
                        transform: rotateY(360deg);
                      
                    }
                }
            }


            &--1{
                justify-content: center;
                height:150px;
                h4{
                    font-size:25px;
                    @include media(small){
                        font-size:35px
                    }
                    @include media(large){
                        font-size:45px
                    }
                }

                

                @include media(medium){
                    border-right:0;
                    height:360px;
                }

                @include media(xxlarge){
                    height:400px;
                }
                
            }

            &--2{
                background:url('../images/know_photo1.jpg') no-repeat;
                background-size:cover;
                background-position: center;
                height:300px;

                @include media(medium){
                    height:360px
                }

                @include media(xxlarge){
                    height:400px;
                }

                @include media(medium){
                    border-left:0;
                }
            }

            &--8{
                padding:0;
                justify-content: space-between;

                a{
                    display:flex;
                    width:100%;
                    height:100%;
                    padding:0;
                    justify-content: space-between;
                    flex-direction: column;
                }

                .photo{
                    background:url('../images/know_lobby.jpg') no-repeat;
                    background-size:cover;
                    background-position: center;
                    width:100%;
                    height:60%;
                }

                .text{
                    width:100%;
                    height:40%;
                    @include flex-center;
                    h5{
                        padding-left:15px;
                        padding-right:15px;
                    }
                } 
                
                
            }

            // &--bigWidth{
            //     width:50%;
            // }

            // &--halfHeight{
            //     height:180px;
            // }


        }
    }
}