.wptripadvisor_t1_A_8{
    display:none;
}

.wptripadvisor_t1_SPAN_5,
#wprev-slider-1 .wprev_preview_bg1_T1::after{
    display:none;
}

#wprev-slider-1 .wprev_preview_tcolor2_T1{
    display:none !important;
}

a.wprs_rd_more{
    font-weight:bold;
}