.pageSingleOffer{
	margin-top: -8px;
    .item{
        .infoBox{
            .photo{
                height: 230px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position:relative;

                @media(min-width:576px){
                    height: 280px;
                }

                @media(min-width:768px){
                    height: 400px;
                }

                @media(min-width:992px){
                    height: 260px;
                }

                @media(min-width:1200px){
                    height: 500px;
                }

            }

            .panel{
					    padding: 0 50px;
                .elementWrap{
					 padding-top: 13px;
                    min-height: inherit;
                    &.centerCol{
                        padding-top: 13px;
                    }

                    p{
                        text-align: left;
                    }
                }
            }

            .btnBox{
                margin-top:15px;

                a:hover{
                    color:#fff !important;
                }
            }

            .panelBottom{
                padding-top:40px;
                padding-bottom:30px;
                .btnBox{
                    margin:0;
                }
            }

        }
    }    
}