@font-face {    
    font-family: 'amazoneplregular';
    src: url('../fonts/amazonepl_1040pl-webfont.eot');
    src: url('../fonts/amazonepl_1040pl-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/amazonepl_1040pl-webfont.woff2') format('woff2'),
         url('../fonts/amazonepl_1040pl-webfont.woff') format('woff'),
         url('../fonts/amazonepl_1040pl-webfont.ttf') format('truetype'),
         url('../fonts/amazonepl_1040pl-webfont.svg#amazoneplregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

body{
    font-family: 'Lato', sans-serif;
}